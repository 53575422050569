import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ModalConfirm = ({ data, isOpen, index }) => {
  // Access data properties
  // Check if data is defined before destructuring
  const { text, confirm, close, type } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  //refresh page on submit
  const refreshPage = () => {
    // window.location.reload();
  };
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="relative w-full max-w-md max-h-full p-4">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => close(index)}
          >
            <span className="w-6 ">
              <XMarkIcon />
            </span>
          </button>
          <div className="p-4 text-center md:p-5">
            <svg
              className="w-12 h-12 mx-auto mb-4 text-gray-400 dark:text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {text}
            </h3>
            <button
              data-modal-hide="popup-modal"
              type="button"
              className={`${
                type === "success" ? "button-success" : "button-danger"
              } mr-2`}
              onClick={() => {
                confirm();
                close(index);
              }}
            >
              {type === "success" ? "Da, sačuvaj" : "Da, obriši"}
            </button>
            <button
              data-modal-hide="popup-modal"
              type="button"
              className="px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-200 rounded-md hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={() => close(index)}
            >
              Ne, odustajem
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
