import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";

const PopupSuccess = ({ label, type }) => {
  const [visible, setVisible] = useState(true);

  const bgColor = type === "success" ? "bg-green-100" : "bg-red-100";
  const borderColor =
    type === "success" ? "border-green-500" : "border-red-500";
  const textColor = type === "success" ? "text-green-500" : "text-red-500";
  const iconPath =
    type === "success"
      ? "M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      : "M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0ZM8 8l8 8m0 -8l-8 8";

  return (
    <>
      {visible && (
        <div
          className={`fixed z-50 flex items-center justify-center mt-2 right-10 bottom-10 animate-fadeOut ${bgColor} ${borderColor} ${textColor}`}
        >
          <div
            className={`p-4 border-l-4 rounded-lg shadow-md ${bgColor} ${borderColor}`}
          >
            <div className="flex items-center w-full">
              <div className={textColor}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="mr-2 w-7 h-7"
                >
                  <path d={iconPath} />
                </svg>
              </div>
              <div className="font-medium text-black text-md">{label}</div>
              <div className="flex justify-end ml-3">
                <button onClick={() => setVisible(false)}>
                  <XMarkIcon className="w-5 h-6 text-gray-500" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupSuccess;
