import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getMembers, resetStatusSendMessage, sendMessage } from "../../api/slices/memberSlice";
import PopupSuccess from "../modals/PopupSuccess";
import Loader from "../common/Loader";
import { XMarkIcon } from "@heroicons/react/24/outline";

const SendMessage = ({onClose, data}) => {
  const [selectedUsers, setSelectedUsers] = useState();
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const plainTextMessage = message.replace(/<[^>]+>/g, "\n");
  const dispatch = useDispatch();
  const { statusSendMessage } = useSelector((state) => state.members);


  useEffect(() => {
    //reset status - send message
    dispatch(resetStatusSendMessage()); 
  }, [dispatch]); 


  // func to separate id and emails from userId data
  const separateIdsAndEmails = (data) => {
    const ids = data.map((item) => item.id);
    const emails = data.map((item) => item.email);
    return { ids, emails };
  };

  const { ids, emails } = separateIdsAndEmails(data);
  const handleEditorChange = (value) => {
    setMessage(value);
  };

  //func to send message
  const handleSubmit = () => {
    dispatch(
      sendMessage({
        users: ids,
        subject: subject,
        data: message,
      })
    );
    // Reset values after submit
    setSubject("");
    setMessage("");
    setSelectedUsers([]);
  };

  const handleModalConfirm = () => {
    handleSubmit();
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
      <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg dark:bg-primary-800">
        <div className="flex justify-end">
        <XMarkIcon className="w-5 h-5 cursor-pointer" onClick={onClose}/>
        </div>
       

        <h1 className="mb-4 text-2xl font-semibold text-gray-800 dark:text-white">
          Pošaljite poruku
        </h1>

        {/* Display selected users */}
        <div className="mb-4">
          {emails?.length > 0 && (
            <ul className="mb-4 text-sm text-gray-600 dark:text-gray-300 list-disc list-inside">
              {emails.map((e, index) => (
                <li key={index}>{e}</li>
              ))}
            </ul>
          )}
          {/* Subject input */}
          <h2 className="mb-2 text-lg font-medium text-gray-700 dark:text-white">
            Subject:
          </h2>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
            placeholder="Unesite subject maila"
          />
        </div>

        {/* Message editor */}
        <div className="mb-4">
          <h2 className="mb-2 text-lg font-medium text-gray-700 dark:text-white">
            Poruka:
          </h2>
          <ReactQuill
            value={message}
            onChange={handleEditorChange}
            theme="snow"
            className="bg-white border border-gray-300 rounded shadow-sm"
            style={{ whiteSpace: "pre-wrap" }}
          />
        </div>

     {/* Button send*/}
     <button
          onClick={() => setIsModalOpen(true)}
          className="w-full px-4 py-2 font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
        >
          Pošaljite
        </button>

        {/* Show modal confirm*/}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold text-gray-700">
                Da li ste sigurni da želite poslati ovu poruku?
              </h3>
              <div className="flex justify-end mt-4 space-x-4">
                <button
                  onClick={handleModalCancel}
                  className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-200"
                >
                  Otkazati
                </button>
                <button
                  onClick={handleModalConfirm}
                  className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                >
                  Potvrdi
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Status messages */}
        {statusSendMessage === "succeeded" && (
        <PopupSuccess label="Uspešno poslato obaveštenje!" type="success" />
      )}
      {statusSendMessage === "failed" && (
        <PopupSuccess label="Došlo je do greške, pokušajte ponovo." type="error" />
      )}
      {statusSendMessage === "loading" && <Loader />}
      </div>
    </div>
  );
};

export default SendMessage;
