// Pagination.jsx
import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline"; // ... (other imports)

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxVisiblePages = 3;
  const getVisiblePages = () => {
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );

    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages) || 1;
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const renderPageButtons = () => {
    const visiblePages = getVisiblePages();
    return (
      <>
        <button
          className={`flex ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          <span className="w-4">
            <ChevronDoubleLeftIcon />
          </span>
        </button>
        <button
          className={`flex ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="w-4">
            <ChevronLeftIcon />
          </span>
        </button>
        {visiblePages.map((page) => (
          <button
            key={page}
            className={`px-3 py-1 border rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 ${
              currentPage === page ? "bg-gray-200 dark:bg-gray-600" : ""
            }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        <button
          className={`flex ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <span className="w-4">
            <ChevronRightIcon />
          </span>
        </button>
        <button
          className={`flex ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          <span className="w-4">
            <ChevronDoubleRightIcon />
          </span>
        </button>
      </>
    );
  };

  return (
    <div className="flex items-center justify-center p-4 space-x-2">
      {renderPageButtons()}
    </div>
  );
};

export default Pagination;
