import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { memberService } from "../services/memberService";

//get all employees
export const getMembers = createAsyncThunk(
  "/final-exams/get",
  async (data, thunkAPI) => {
    try {
      return await memberService.fetchMembers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// create new user
export const createUser = createAsyncThunk(
  "/standards/add",
  async (data, thunkAPI) => {
    try {
      return await memberService.createUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//deactivate user
export const deactivateUser = createAsyncThunk(
  "/task/deactivate-user",
  async (data, thunkAPI) => {
    try {
      return memberService.deactivateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// csend message
export const sendMessage = createAsyncThunk(
  "/sendMessage/post",
  async (data, thunkAPI) => {
    try {
      return await memberService.sendMessage(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const memberSlice = createSlice({
  name: "member",
  initialState: {
    membersData: null,
    loading: false,
    error: null,
    status: null,
    statusSendMessage:null,
    message: null,
    messages: null,
  },
  reducers: {
    resetStatusSendMessage: (state) => {
      state.statusSendMessage = null;
    },
    resetStatusCreateUser: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.membersData = action.payload;
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.status = "succeeded";
          state.message = action.payload.data.message || "uspesni";
        } else {
          state.status = "failed";
          state.message = "Došlo je do greške, pokušajte ponovo.";
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload?.errors || "Greška ";
      })
      .addCase(deactivateUser.pending, (state) => {
        state.loadingMembers = true;
        state.error = null;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.loadingMembers = false;
        state.data = action.payload;
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.loadingMembers = false;
        state.error = action.error.message;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.statusSendMessage = "succeeded";
          state.messages = action.payload.data.message || "uspesni";
        } else {
          state.statusSendMessage = "failed";
          state.messages = "Došlo je do greške, pokušajte ponovo.";
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.statusSendMessage = "failed";
        state.messages =
          action.payload?.errors || "Greška pri ažuriranju lozinke";
      })
      .addCase(sendMessage.pending, (state) => {
        state.statusSendMessage = "loading";
      });
  },
});
export const { resetStatusSendMessage , resetStatusCreateUser} = memberSlice.actions;

export default memberSlice.reducer;
