import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { finalExamService } from "../services/finalExamService";

//get final exam
export const getFinalExams = createAsyncThunk(
    "/get",
    async (data, thunkAPI) => {
      try {
        return await finalExamService.getFinalExams();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  //generate final exam for page "Moji testovi"
export const generateFinalExam = createAsyncThunk(
    "/final-exam/get",
    async (data, thunkAPI) => {
      const { id } = data;
      try {
        return await finalExamService.generateFinalExam(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

// get exam list
export const getFinalExamList = createAsyncThunk(
  "/exams-list/get",
  async (data, thunkAPI) => {
    try {
      return await finalExamService.getFinalExamList();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//post test to user
export const postTestToUser = createAsyncThunk(
  "/test/post",
  async (data, thunkAPI) => {
    try {
      return await finalExamService.postTestToUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get tests for user
export const getAllTests = createAsyncThunk(
  "/all-tests/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await finalExamService.getAllTests(id)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);


  const finalExamSlice = createSlice({
    name: "final-exams",
    initialState: {
    finalExamsData: null,
    finalExamList:null,
    testsData:null,
      loadingFinalExam: false,
      loading:false,
      error: null,
      status:null,
      message:null
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getFinalExams.pending, (state) => {
          state.loadingFinalExam = true;
          state.error = null;
        })
        .addCase(getFinalExams.fulfilled, (state, action) => {
          state.loadingFinalExam = false;
          state.finalExamsData = action.payload;
        })
        .addCase(getFinalExams.rejected, (state, action) => {
          state.loadingFinalExam = false;
          state.error = action.error.message;
        })
        .addCase(generateFinalExam.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(generateFinalExam.fulfilled, (state, action) => {
          if (action.payload.code === 200) { 
            state.status = 'succeeded';
            if(action.payload.data.message==="you already passed, go get your certificate"){
              state.message='polozio'
            }
            else{
              state.message = action.payload.data.message ;
            }
          
          } else {
            state.status = 'failed';
            state.message = 'Vec ste polozili, preuzmite sertifikat';
          }
        })
        .addCase(generateFinalExam.rejected, (state, action) => {
            state.status = 'failed';
            state.message = action.payload?.errors || 'Greška pri generisanju zavrsnog testa';
        })
        .addCase(getFinalExamList.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getFinalExamList.fulfilled, (state, action) => {
          state.loading = false;
          state.finalExamList = action.payload;
        })
        .addCase(getFinalExamList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(getAllTests.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getAllTests.fulfilled, (state, action) => {
          state.loading = false;
          state.testsData = action.payload;
        })
        .addCase(getAllTests.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
    },
  });
  
  export default finalExamSlice.reducer;
  