import storage from "redux-persist/lib/storage"; // default: localStorage
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../api/slices/authSlice";
import { persistStore, persistReducer, REGISTER } from "redux-persist";
import courseReducer from "../api/slices/courseSlice";
import settingsReducer from "../api/slices/settingsSlice";
import testReducer from "../api/slices/testSlice";
import finalExamReducer from "../api/slices/finalExamSlice";
import memberReducer from "../api/slices/memberSlice";
import dashboardReducer from "../api/slices/dashboardSlice";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
};
// Kreiranje perzistentnog reducera
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    courses: courseReducer,
    settings: settingsReducer,
    testResults: testReducer,
    finalExams: finalExamReducer,
    members: memberReducer,
    dashboard: dashboardReducer,

    devTools: process.env.NODE_ENV !== "production", // Enable only in development
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
