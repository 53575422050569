import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuizResults from "./QuizResults";
import {
  getFinalExam,
  postAnswerFinalExam,
} from "../../api/slices/courseSlice";
import { useNavigate, useParams } from "react-router-dom";
import { generateFinalExam } from "../../api/slices/finalExamSlice";
//========================================================Final Exam Component==============================================
const QuizPage = ({index, onNextQuestion, answers, setAnswers, sectionList}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [essayAnswer, setEssayAnswer] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(index);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const { finalExamData } = useSelector((state) => state.courses);
  const quiz = finalExamData?.data?.Quiz;
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const currentQuestion = quiz?.questions?.[currentQuestionIndex];

  let toSend = quiz;

  const [selectedAnswers, setSelectedAnswers] = useState(answers);
  // select answwer - multiple choice question
  const handleMultipleChoiceChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  // select answwer - single choice question
  const handleSingleChoiceChange = (option) => {
    setSelectedOption(option);
  };

  // make copy of quiz data and add selected answer, then pass it as preparedData
  const prepareDataForSubmission = (quiz, selectedAnswers) => {
    const dataCopy = JSON.parse(JSON.stringify(quiz));
    dataCopy.updatedAt = new Date().toISOString();

    dataCopy.questions = dataCopy.questions.map((question) => {
      question.updatedAt = new Date().toISOString();
      question.answers = question.answers.filter((answer) =>
        selectedAnswers.includes(answer.answer)
      );
      return question;
    });
    return dataCopy;
  };

  //func to answer final exam
  const handleSubmit = async () => {
    let selectedAnswer;

    if (currentQuestion.questionType === "multiple_choice") {
      selectedAnswer = selectedOptions;
     
    } else if (currentQuestion.questionType === "single_choice") {
      selectedAnswer = [selectedOption];
  
    } else if (currentQuestion.questionType === "essay") {
      selectedAnswer = [essayAnswer];
    }

    // Move to next question or submit if it is the last question
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setSelectedAnswers((prevAnswers) => [...prevAnswers, ...selectedAnswer]);
      setAnswers((prevAnswers)=>[...prevAnswers, ...selectedAnswer])
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOptions([]);
      setSelectedOption(null);
      setEssayAnswer("");
    } else {
      const finalAnswers = [...selectedAnswers, ...selectedAnswer];
      const preparedData = prepareDataForSubmission(quiz, finalAnswers);
      //post answer to final exam
      const response = await dispatch(postAnswerFinalExam(preparedData));
      setPercentage(
        (response.payload.data.Points / quiz.numberOfQuestions) * 100
      );
      setQuizCompleted(true);
    }
    onNextQuestion();
  };
  const {finalExamsData, status, message}= useSelector((state)=>state.finalExams)
  useEffect(() => {
    // provera statusa
    if (status === 'succeeded' || status === 'failed') {
       
    }
  }, [status, message]);

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedOptions([]);
    setSelectedOption(null);
    setEssayAnswer("");
    setQuizCompleted(false);

    if(sectionList.length>0){
      dispatch(getFinalExam({ id }));
    }
    else(
      dispatch(generateFinalExam({id})));
  };

  return (
    <div className="w-full p-6 mb-2 rounded-lg lg:w-3/4 bg-primary-55 dark:bg-primary-800">
      {(quizCompleted || status === 'failed') || message==='polozio'  ? (
        <QuizResults resetQuiz={resetQuiz} percentage={percentage} check={message}/>
      ) : (
        <>
          {/* progress bar for questions */}
          <div className="flex items-center justify-center mt-6 mb-6 space-x-2">
            {[...Array(quiz?.questions.length)].map((_, idx) => (
              <div
                key={idx}
                className={`h-2 w-2 rounded-full ${
                  idx < currentQuestionIndex ? "bg-blue-500" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
          {/* check question type and show questions */}
          {currentQuestion ? (
            <div className="p-6 rounded-lg shadow-md bg-primary-55 dark:bg-primary-800">
              <h2 className="mb-4 text-xl font-bold">
                {currentQuestion.question}
              </h2>

              {currentQuestion.questionType === "multiple_choice" && (
                <form className="space-y-4">
                  {currentQuestion.answers.map((answer, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`option-${index}`}
                        className="mr-2"
                        checked={selectedOptions.includes(answer.answer)}
                        onChange={() =>
                          handleMultipleChoiceChange(answer.answer)
                        }
                      />
                      <label
                        htmlFor={`option-${index}`}
                        className="text-gray-700 dark:text-white"
                      >
                        {answer.answer}
                      </label>
                    </div>
                  ))}
                </form>
              )}

              {currentQuestion.questionType === "single_choice" && (
                <form className="space-y-4">
                  {currentQuestion.answers.map((answer, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="radio"
                        id={`option-${index}`}
                        name="single-choice"
                        className="mr-2"
                        checked={selectedOption === answer.answer}
                        onChange={() => handleSingleChoiceChange(answer.answer)}
                      />
                      <label
                        htmlFor={`option-${index}`}
                        className="text-gray-700 dark:text-white"
                      >
                        {answer.answer}
                      </label>
                    </div>
                  ))}
                </form>
              )}

              {currentQuestion.questionType === "essay" && (
                <form className="space-y-4">
                  <textarea
                    className="w-full p-2 border rounded-lg"
                    rows="5"
                    placeholder="Napišite odgovor ovde..."
                    value={essayAnswer}
                    onChange={(e) => setEssayAnswer(e.target.value)}
                  />
                </form>
              )}

              <button
                type="button"
                onClick={handleSubmit}
                className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg"
              >
                Submit
              </button>
            </div>
          ) : (
            <p>No questions available.</p>
          )}
        </>
      )}
    </div>
  );
};

export default QuizPage;
