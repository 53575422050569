import { useState } from "react";
export const useSortableTable = (data) => {
 const [tableData, setTableData] = useState(data);

 const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {

        const getValue = (obj, field) => field.split('.').reduce((o, key) => o?.[key], obj);
  
        const aValue = getValue(a, sortField);
        const bValue = getValue(b, sortField);
  
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;
        if (aValue === null && bValue === null) return 0;
  
        return (
          aValue.toString().localeCompare(bValue.toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
  
      setTableData(sorted);
    }
  };
  

 return {tableData, handleSorting};
};