import { useDispatch } from "react-redux";
import DashboardPanel from "../../components/dashboard_components/DashboardPanel";
import Sidebar from "../../components/dashboard_components/SideBar";
import { SidebarProvider } from "../../context/SidebarContext";
import withAuth from "../../HOCs/withAuth";
import { logout } from "../../api/slices/authSlice";

const Dashboard = ({ model, projects }) => {
  const dispatch = useDispatch();
  if (JSON.parse(localStorage.getItem("shouldLogout"))) {
    dispatch(logout());
    localStorage.setItem("shouldLogout", false);
  }

  return (
    <SidebarProvider className="h-screen ">
      <div className="relative flex">
        <Sidebar />
        <DashboardPanel model={model} projects={projects} />
      </div>
    </SidebarProvider>
  );
};

export default withAuth(Dashboard);
