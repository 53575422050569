import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../api/services/authService";
import { clearError, startLoading } from "../../api/slices/authSlice";
import { Navigate } from "react-router-dom";
import {
  composeValidators,
  requiredFieldValidation,
} from "../../utils/helpers/validations";
import { InputField, PasswordInputField } from "../../components/common/FormComponents";
import Notification from "../../components/common/Notification";
import logoImg from "../../assets/images/logo3.svg";
import ResetPassword from "./ResetPassword";

const Login = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated || false
  );
  console.log(isAuthenticated);

  const userRole  = useSelector((state) => state.auth.user?.role.roleName);
  //Get error
  const authError = useSelector((state) => state.auth.error || "");
  const loading = useSelector((state) => state.auth.loading || false);

  

  //If user is authenticated, redirect to
  if (isAuthenticated) {
    return userRole === "admin" ? (
      <Navigate to="/dashboard/my-dashboard" />
    ) : (
      <Navigate to="/dashboard/tests" />
    );
  }

  const onSubmit = (formValues) => {
    dispatch(startLoading());
    dispatch(login(formValues));
  };

  return (
    <>
      <section className="flex flex-col items-center justify-between min-h-screen bg-gray-50 dark:bg-primary-750">
        <div className="flex items-center justify-center flex-grow w-full">
          <div className="flex flex-col items-center w-full px-5">
            <Link
              to="#"
              className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="h-10 mr-2 w-30" src={logoImg} alt="logo" />
            </Link>
            <div className="mb-2 text-xl dark:text-white"></div>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:px-0 dark:bg-primary-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="relative flex flex-col">
                        {/* Username  input field */}
                        <Field
                          name="username"
                          component={InputField}
                          type="text"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Unesite Vaš email ili korisničko ime"
                          label="Vaš email"
                        />
                        {/* Username  input field */}

                        <Field
                          name="password"
                          component={PasswordInputField}
                          type="password"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="••••••••••••••"
                          label="Vaša lozinka"
                        />
                      </div>
                      {!!authError && (
                        <Notification
                          type="fail"
                          text={authError}
                          changeNotificationVisibility={() =>
                            dispatch(clearError())
                          }
                        />
                      )}
                      <button
                        type="submit"
                        className="w-full text-white bg-primary-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        {loading ? "Logovanje..." : "Uloguj se"}
                      </button>
                    </form>
                  )}
                />

                {/* <div className="flex items-center justify-center mt-0">
                  <Link
                    to="/register/"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Nemate nalog? Registrujte se!
                  </Link>
                </div> */}
                <div className="flex items-center justify-center mt-0">
                  <Link
                    to="/reset-password-1/"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Zaboravili ste lozinku?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
