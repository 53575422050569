import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import TableRowSelection from "../common/tables/TableRowSelection";
import { formatDateTime } from "../../utils/helpers/formatDate";

const ContentSideBar = ({ handleClose, data }) => {
  const [isClosing, setIsClosing] = useState(false);

  //animation for closing contentSideBar
  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(handleClose, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [isClosing, handleClose]);

  const handleCloseWithAnimation = () => {
    setIsClosing(true);
  };

  const headers = [
    { name: "ID", key: "data.id" },
    { name: "Ime", key: "data?.message" },
    { name: "Kurs id", key: "data?.course?.author?.email" },
    { name: "Aktiv", key: "data?.course?.active " },
    { name: "comp id", key: "data?.companyId" },
  ];
  //number of columns in pagination
  const itemsPerPage = 5;
  //search hint "pretraga po"
  const searchText = "ID";

  const questionsArray = Object.keys(data)
    .filter((key) => key.startsWith("questions."))
    .reduce((acc, key) => {
      const [_, index, subKey] = key.split(".");
      if (!acc[index]) acc[index] = {}; // inicijalizacija objekta za svako pitanje
      if (subKey === "answers") {
        //odgovori su u nizu
        acc[index][subKey] = acc[index][subKey] || [];
        if (data[key] !== null && typeof data[key] === "object") {
          acc[index][subKey].push(data[key]);
        }
      } else {
        acc[index][subKey] = data[key] || null; // vrednosti se postavljaju direktno, ukoliko in nema null
      }
      return acc;
    }, []);

  return (
    <>
      <div
        className={`absolute block z-40 top-0 h-full right-0 xl:w-1/2 bg-white dark:bg-primary-800 animate-slide-in-right md:w-full w-full  ${
          isClosing && "animate-close-content-side-bar"
        }`}
      >
        <div className="absolute z-30 block w-6 h-6 rounded-full cursor-pointer right-2 lg:visible top-2">
          <XMarkIcon onClick={handleCloseWithAnimation}></XMarkIcon>
        </div>
        <div className="p-10 mt-12">
          <div className="px-12 py-6 rounded-md shadow-md ">
            <p>
              <strong>Naziv</strong>{" "}
            </p>
            <p>{data["course.name"]}</p>
            <p>
              <strong>Opis</strong>
            </p>
            <p>{data["course.courseDescription"]}</p>
            <p>
              <strong>Trajanje</strong>
            </p>
            <p> {data["course.duration"]} minuta</p>
          </div>
          <div className="px-12 py-6 rounded-md shadow-md">
            <p>
              <strong>Vreme aktiviranja testa</strong>
            </p>
            <p>{formatDateTime(data?.StartedAt)}</p>
            <p>
              <strong>Vreme završetka testa</strong>
            </p>
            <p> {formatDateTime(data?.finishedAt)}</p>
            <p>
              <strong>Mogući poeni</strong>
            </p>
            <p>{data?.maximumPoints}</p>
            <p>
              <strong>Osvojeni poeni</strong>
            </p>
            <p>{data?.points}</p>
            <p>
              <strong>Status</strong>
            </p>
            <p>{data?.passed ? "Položen" : "Nije položen"}</p>
          </div>
        </div>

        {/* <TableRowSelection
        data={data.data}
        headers={headers}
        itemsPerPage={itemsPerPage}
        searchText={searchText}
        button=""
        componentType=""
      /> */}
      </div>
    </>
  );
};

export default ContentSideBar;
