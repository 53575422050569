import React, { useState, useEffect, useRef } from "react";
import { Error } from "../../errors/FormValidationErrorField";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

//INPUT FIELD - React Final Form
export const InputField = ({
  input,
  label,
  disabled,
  icon,
  placeholder,
  meta,
  id,
}) => {
  return (
    <div className="w-full mb-5">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">
        {label}
      </label>
      <input
        disabled={disabled}
        {...input}
        placeholder={placeholder}
        id={input.id}
        type={input.type}
        className={`${
          icon ? "input-primary-with-icon " : "input-primary"
        } border ${
          meta.error && meta.touched
            ? "border-red-600 dark:border-red-600"
            : "border-gray-300"
        }`}
      />
      {!disabled && <Error name={input.name} />}
    </div>
  );
};

//DROPDOWN
export const Dropdown = ({ input, options, text, onChange }) => {
  return (
    <div className="mt-2 mb-3">
      <select onChange={onChange} {...input} className=" input-primary">
        <option value="">{text}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <Error name={input.name} /> */}
    </div>
  );
};

//DROPDOWN
export const DropdownCourse = ({ input, optionsCourse, text, onChange }) => {
  return (
    <div className="mt-2 mb-3">
      <select onChange={onChange} {...input} className=" input-primary">
        <option value="">{text}</option>
        {optionsCourse.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <Error name={input.name} /> */}
    </div>
  );
};


export const TagDropdown = ({
  columnKey,
  text,
  options,
  selected,
  onSelectionChange,
  onClear,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Handle selecting/deselecting a filter option for a specific column
  const handleSelect = (key, option) => {
    onSelectionChange((prevFilters) => {
      const isSelected = prevFilters[key]?.includes(option);
      const updatedSelected = isSelected
        ? prevFilters[key].filter((item) => item !== option) // Remove if selected
        : [...(prevFilters[key] || []), option]; // Add if not selected

      return { ...prevFilters, [columnKey]: updatedSelected };
    });
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left pl-2">
      <button
        onClick={toggleDropdown}
        className="px-4 py-2 rounded-md hover:bg-gray-700 dark:hover:bg-gray-700 focus:outline-none bg-primary-800 dark:bg-primary-800 text-white"
      >
        {text}
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-56 rounded-md shadow-lg dark:bg-primary-800  bg-gray-50 ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold text-gray-400">{text}</h3>
              <button
                onClick={() => onClear()}
                className="text-sm text-blue-500 hover:underline"
              >
                Clear
              </button>
            </div>

            {/* Tag options */}
            <div className="space-y-2">
              {options.map(({ label, value }) => (
                <div
                  key={value}
                  className="flex items-center cursor-pointer"
                  onClick={() => handleSelect(columnKey, value)}
                >
                  <input
                    type="checkbox"
                    checked={selected[columnKey]?.includes(value) || false}
                    readOnly
                    className="mr-2 rounded"
                  />
                  <span>{label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


//InputField for Password
export const PasswordInputField = ({ input,label, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full">
    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">
      {label}
    </label>
    <div className="relative mt-2 mb-3">
 
      <input
        {...input}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        className="w-full pr-10 input-primary"
      />
      <div
        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
        onClick={toggleShowPassword}
      >
        {showPassword ? (
          <EyeIcon className="w-5 h-5 text-gray-500" />
        ) : (
          <EyeSlashIcon className="w-5 h-5 text-gray-500" />
        )}
      </div>
      <Error name={input.name} />
    </div>
    </div>
  );
};