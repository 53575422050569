import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const InfoCircle = ({ type, searchBy, text }) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <>
      <div className="w-6 ml-2 mr-2 relative">
        <InformationCircleIcon
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onTouchStart={() => setHovered(true)}
          onTouchEnd={() => setHovered(false)}
        />
        {isHovered && type === "search" && (
          <div className="absolute z-50 p-3 mt-4 bg-white rounded-lg shadow lg:w-52 dark:bg-primary-800 dark:border-primary-200">
            Pretragu možete vršiti prema:
            {Array.isArray(searchBy) ? (
              <ul className="list-disc pl-5">
                {searchBy.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{searchBy}</p> 
            )}
          </div>
        )}
        {isHovered && type === "info" && (
          <div className="absolute z-50 p-3 transform -translate-x-full bg-white rounded-lg shadow lg:w-52 dark:bg-primary-800 dark:border-primary-200">
            {text}
          </div>
        )}
      </div>
    </>
  );
};

export default InfoCircle;
