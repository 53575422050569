import React from "react";
import { Field } from "react-final-form";

export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <div className="absolute text-xs text-red-600">
          <p className="whitespace-normal">{error}</p>
        </div>
      ) : null
    }
  />
);

export const DateConsistencyError = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { error } }) =>
      error && (
        <span className="absolute text-xs text-red-600 -bottom-3 ">
          {error}
        </span>
      )
    }
  />
);
