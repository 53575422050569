import React, { useEffect, useState } from "react";
import CourseProgressBar from "./CourseProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourses,
  getFinalExam,
  getLessons,
  setCourseCompleted,
  setLessonCompleted,
  setSectionCompleted,
} from "../../api/slices/courseSlice";
import { useParams } from "react-router-dom";
import CourseDetailsContent from "./CourseDetailsContent";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import BasicQuizPage from "./BasicQuizPage";
import QuizPage from "./QuizPage";
import ProgressBar from "../common/ProgressBar";  
import Message from "../common/Message";
import { generateFinalExam } from "../../api/slices/finalExamSlice";
import placeholderMan from "../../assets/images/placeholderMan.jpg";

function SidebarCourseDetails({sections}) {
  
  const { lessonsData } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  //ukupno sekcija u kursu
  const [sectionList, setSectionList] = useState([]);
  const [coursePercentage, setCoursePercentage] = useState(0);
  const [progressions, setProgressions] = useState(new Map());

  const [checkedLessons, setCheckedLessons]= useState(new Map())

  const[questionIndex, setQuestionIndex]= useState(0)
  const[selectedAnswers, setSelectedAnswers]= useState([])

  // open dropdown if there is lessons
  const [openSection, setOpenSection] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [isQuizActive, setIsQuizActive] = useState(false);
  const [isFinalExamActive, setIsFinalExamActive] = useState(false);
  const [finalExamQuestionsFetched, setFinalExamQuestionsFetched] = useState(false)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [section, setSection] = useState(sections?.sections);

const [showMessage, setShowMessage]=useState(false);
const allLessons = lessonsData?.data.section.lessons;

const[completedLessons, setCompletedLessons]= useState(Array(allLessons?.length).fill(false))
const [messageText, setMessageText] = useState(Array(allLessons?.length).fill(false));

//===============================================================================================================================================

const incrementQuestionIndex =()=>{
  setQuestionIndex(questionIndex+1);
}
const calculateCourseProgress =(s) => {
  var finish = 0;
  for (let i = 0; i < s.length; i++) {
    if (s[i]) {
      finish += 1;
    }
  }
  if (Math.ceil((finish / s.length) * 100) >= 100) {
    setCoursePercentage(100);
  } else {
    setCoursePercentage(Math.ceil((finish / s.length) * 100));
  }


};

  //ovde update-ujem progression
  useEffect(() => {
    if (sections?.sections?.length > 0) {
      //progression for cicrcle progress bar in setion
      const initialProgressions = new Map(
        sections?.sections?.map((section) => [
          section.id,
          section.progression || 0,
        ])
      );
      // add check icon when lection status === "Completed"
      const initialCheckLessons = new Map(
        sections?.sections?.map((section)=>
        section?.lessons.map((lesson)=>[
          lesson.id,
          lesson.status || "In progress",
        ])
      )
      );

      const p = sections?.sections?.map((sec) => {
        return sec.status === "Completed";
      });
      setSectionList(p);
      setProgressions(initialProgressions);
      setCheckedLessons(initialCheckLessons);
      calculateCourseProgress(p);
    }
  }, [sections]);




  //func koja se prosljedjuje za dugme Finish u Content komponenti
  const handleCompleteLesson = (index) => {
    const newCompletedLessons = [...completedLessons];
    newCompletedLessons[index] = true;
    setCompletedLessons(newCompletedLessons);
    
    dispatch(setLessonCompleted({ id: allLessons[index].id })).then(()=>dispatch(getLessons({ id: lessonsData?.data?.section?.id })));
    // if (index === allLessons.length - 1) {
    //   dispatch(setSectionCompleted({ id: lessonsData.data.section.id }));
    // }

    const currentSectionIndex = sections.sections.findIndex(
      (section) => section.id === openSection
    );
    setSelectedSectionIndex(currentSectionIndex);

    // if (
    //   sections.sections[currentLessonIndex].lessons[index].status != "Completed"
    // ) {
    //   // sections.sections[currentLessonIndex].lessons[index].status = "Completed";
    //   const newProgressions = [...progressions];
    //   newProgressions[currentSectionIndex] += 100 / allLessons.length;
    //   if (newProgressions[currentSectionIndex] >= 100) {
    //     newProgressions[currentSectionIndex] = 100;
    //   }
    //   setProgressions(newProgressions);
    // }
  }; //===================================== zavrsetak provere isCompletedLesson=========================


  // func for adding check icon, this is passed to CourseDetailsContent component
  const checkAnswersUpdate =(lessonId)=>{
    const newCheckedLessons = new Map(checkedLessons);
    newCheckedLessons.set(lessonId, "Completed");

    setCheckedLessons(newCheckedLessons);
  };

  //=============================================== ovde se nalazi logika za kruzni progress bar (za sekciju)==========================================
  const progressionUpdate = async (selectedSectionId) => {
    const newProgressions = new Map(progressions);

    // Izračunaj novi progres za izabranu sekciju(kruzni progress)
    const currentProgress = newProgressions.get(selectedSectionId) || 0;
    const updatedProgress = Math.ceil(
      currentProgress + 100 / allLessons.length
    );
    
    // Postavi novi progres za izabranu sekciju(kruzni progress)
    newProgressions.set(
      selectedSectionId,
      updatedProgress >= 100 ? 100 : updatedProgress
    );
    if (updatedProgress >= 100) {
      dispatch(setSectionCompleted({ id: lessonsData.data.section.id })).then(()=>dispatch(fetchCourses()));
      const updatedSections = sections.sections.map((sec) => {
        if (sec.id === selectedSectionId) {
          return { ...sec, status: "Completed"};
        } else {
          return sec;
        }
      });

      

      // Ažuriranje stanja sekcija pomoću setSections
      setSection((prevState) => ({
        ...prevState,
        sections: updatedSections,
      }));

      // Kreiranje liste sa statusom završenih sekcija
      const completionStatus = updatedSections.map((sec) => sec.status === "Completed");
      calculateCourseProgress(completionStatus);
    }
    
    // Update sa novim progressom
    setProgressions(newProgressions);
    
    
    //ukoliko su sve sekcije gotove, oznaci kurs kao gotov
    for (let [key, value] of newProgressions) {
      if (value < 100) {
       
        return;
      }
    }
 
    //setujem kurs status completed
    dispatch(setCourseCompleted({ id }));

    
  };

  const toggleQuizActive = (isActive) => {
    setIsQuizActive(isActive);
  };

  // select lesson
  const handleLessonClick = (lesson, index) => {
    setIsFinalExamActive(false);
    //provera da li je lekcija zavrsena, da bi moglo da se ide na sledecu
    if (index > 0) {
      if (
        lessonsData?.data?.section?.lessons[index - 1].status !== "Completed" &&
        checkedLessons.get(lessonsData?.data?.section?.lessons[index-1]?.id) !== "Completed"
      ) {
        //show message
       setMessageText("Morate završiti prethodne lekcije!");
       setShowMessage(true);
        return;
      }
    }
    
    setSelectedLesson(lesson);
    setIsQuizActive(false);
  };

  

  //close message
  const closeMessage=()=>{
    setShowMessage(false);
  }

  // activate basic quiz (quiz inside sections)
  const handleQuizClick = (quizId) => {
    setSelectedLesson({ id: quizId, isQuiz: true });
    setIsQuizActive(true);
  };

  // open section and get lesson content
  const handleCourseClick = (section, index) => {
    setIsFinalExamActive(false);
    if (openSection === section.id) {
      // Zatvori otvorenu sekciju
      setOpenSection(null);
    } else {
      setSelectedLesson(null);

      // Ucitaj sve lekcije za sekciju
      dispatch(getLessons({ id: section.id }));
      setOpenSection(section.id);
    }
  };


  //========================= show modal before navigate to Final Exam===========================================
  const [showModal, setShowModal] = useState(false);
  const [isReturningToLesson, setIsReturningToLesson] = useState(false);
  const { id } = useParams();
  const { finalExamData } = useSelector((state) => state.courses);

  // func shows modal before going to final exam
  const handleButtonClick = () => {

    if(sections?.sections?.length>0){
      //check if all sections have status==="Completed"
    const allSectionsCompleted = sections?.sections?.every(section => section.status === 'Completed');
    
    if (!allSectionsCompleted) {
         //show message
         setMessageText("Morate završiti prethodne lekcije!");
         setShowMessage(true);
          return;
    } else {
      if (!finalExamQuestionsFetched) {
        setShowModal(true);
      } else {
        setIsFinalExamActive(true);
      }
    }
  }else{
    return;
  }
  };

  const {finalExamsData, stauts, message}= useSelector((state)=>state.finalExams)
  const confirmNavigation = () => {
    setShowModal(false);
    if(!finalExamQuestionsFetched){
      if(sectionList.length>0){
        dispatch(getFinalExam({ id }));
      }
      else(
        dispatch(generateFinalExam({id})));
        
      setFinalExamQuestionsFetched(true)
    }
   
    setIsFinalExamActive(true);
  };

  const cancelNavigation = () => {
    setShowModal(false);
  };

  //=============================end of show modal for FInal Exam func===============================================

  //=============================== handle next function===================================
  const handleNextClick = () => {
    const currentSectionIndex = sections.sections.findIndex(
      (section) => section.id === openSection
    );
  
    if (selectedLesson?.isQuiz) {
      // Ako korisnik završi kviz, pređi na sledeću sekciju ili lekciju
      const nextSection = sections.sections[currentSectionIndex + 1];
  
      if (nextSection) {
        dispatch(getLessons({ id: nextSection.id }));
        setOpenSection(nextSection.id);
        setSelectedLesson(null);
      } else {
        setShowModal(true); // Otvori modal samo ako više nema sekcija
      }
      return;
    } 
  
    if (selectedLesson) {
      const currentLessonIndex = lessonsData?.data?.section?.lessons?.findIndex(
        (lesson) => lesson.id === selectedLesson.id
      );
  
      // Idi na sledeću lekciju unutar iste sekcije
      const nextLesson =
        lessonsData?.data?.section?.lessons[currentLessonIndex + 1];
  
      if (nextLesson) {
        setSelectedLesson(nextLesson);
        return;
      }
  
      // Ako više nema lekcija u sekciji, proveri da li postoji kviz
      if (sections.sections[currentSectionIndex]?.quizId) {
        handleQuizClick(sections.sections[currentSectionIndex].quizId);
        return;
      }
  
      // Pređi na sledeću sekciju ako više nema lekcija i kvizova
      const nextSection = sections.sections[currentSectionIndex + 1];
      if (nextSection) {
        dispatch(getLessons({ id: nextSection.id }));
        setOpenSection(nextSection.id);
        setSelectedLesson(null);
      } else {
        setShowModal(true); // Otvori modal samo ako više nema sekcija i lekcija
      }
    }
  };
  

  //=============================show current lesson in section==============================
  const currentLessonIndex = lessonsData?.data?.section?.lessons?.findIndex(
    (lesson) => lesson.id === selectedLesson?.id
  );
  // Ukupan broj lekcija u trenutnoj sekciji
  const totalLessonsInSection = lessonsData?.data?.section?.lessons?.length;

  // Ukupan broj lekcija u kursu (u svim sekcijama)
  const totalLessons = allLessons?.length;

  // Broj završenih lekcija
  const completedLessonCount = completedLessons.filter(Boolean)?.length;

  // Izračunavanje napretka u procentima
  const progress = (completedLessonCount / totalLessons) * 100;

  const size = 25;
  const strokeWidth = 3;
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const calculateProgress = (progress) => {
    return circumference - (progress / 100) * circumference;
  };

  return (
    <>
      <div className="w-full lg:min-h-screen md:min-h-screen p-4 mb-2 mr-3 rounded-lg lg:w-1/4 md:flex-row bg-primary-55 dark:bg-primary-800">
      <>
      {sections?.length!=0 &&(
        <div>
        <h2 className="mb-4 text-xl font-bold">{sections?.name}</h2>

        <div className="flex items-center">
        <div className="lg:flex">
              <span className="hidden w-12 h-12 lg:flex ">
                <img src={placeholderMan} alt="User" className="rounded-full" />
              </span>
            </div>
          <p className="items-center ml-2">
            {sections?.author?.firstName} {sections?.author?.lastName}
          </p>
        </div>
        </div>
        )}
        </>
        {sectionList.length > 0 && (
          <>
            <div className="p-2 mt-3 text-xs text-slate-500">
              {isNaN(coursePercentage)?0:coursePercentage}%completed
             
              <ProgressBar percentage={isNaN(coursePercentage)?0:coursePercentage} />
            </div>
          </>
        )}
        <ul className="mt-10">
          {sections?.sections?.length > 0 ? (
            sections.sections.map((section, index) => (
              <li key={section.id}>
                <div
                  className={`flex items-center justify-between p-2 cursor-pointer dark:hover:bg-gray-700 hover:bg-slate-200
            ${
              openSection === section.id
                ? "border-l-4 border-blue-600 text-blue-600 bg-slate-200 dark:bg-gray-700"
                : "hover:text-blue-600 "
            }`}
                  onClick={() => handleCourseClick(section)}
                >
                  {section.name}
                  {section.lessons?.length > 0 || section.quizId ? (
                    <span className="flex-1 ml-2">
                      {openSection === section.id ? (
                        <ChevronUpIcon className="w-4 h-4" />
                      ) : (
                        <ChevronDownIcon className="w-4 h-4" />
                      )}
                    </span>
                  ) : null}
                  {/* Progress circle */}
                  <div className="ml-2">
                    <svg width={size} height={size}>
                      <circle
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        className="text-gray-300 "
                        stroke="currentColor"
                        fill="transparent"
                      />
                      <circle
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        className="text-blue-500"
                        stroke="currentColor"
                        fill="transparent"
                        strokeDasharray={circumference}
                        strokeDashoffset={calculateProgress(
                          progressions.get(section.id)
                        )}
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>

                {/* Show lessons if section is opened*/}
                {openSection === section.id &&
                  lessonsData?.data?.section?.lessons?.length > 0 && (
                    <ul
                      className={`ml-4 lessons-list ${
                        openSection === section.id ? "open" : "closing"
                      }`}
                    >
                      {lessonsData.data.section.lessons.map((lesson, index) => (
                        <li
                          key={lesson.id}
                          className={`flex items-center justify-between p-2 cursor-pointer dark:hover:bg-gray-700 hover:bg-slate-200
          ${
            selectedLesson?.id === lesson.id && !selectedLesson?.isQuiz
              ? "border-l-4 border-blue-600 text-blue-600 bg-slate-200 dark:bg-gray-700"
              : "hover:text-blue-600 "
          }`}
                          onClick={() => handleLessonClick(lesson, index)}
                        >
                          <div className="flex items-center">{lesson.name}</div>
                          {/* automatski dodati nakon submit-a! */}
                          {/* {checkedLessons.get(lesson?.id)=== "Completed"  && (
                            <div className="ml-2">
                              <CheckIcon className="w-5 h-5 text-green-500" />
                            </div>
                          )} */}
                          {lesson.status === "Completed" && (
                            <div className="ml-2">
                              <CheckIcon className="w-5 h-5 text-green-500" />
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}

                {/* show basic quiz */}
                {openSection === section.id && section.quizId && (
                  <ul
                    className={`p-2 ml-4 cursor-pointer dark:hover:bg-gray-700 hover:bg-slate-200 ml-4 lessons-list ${
                        openSection === section.id ? "open" : "closing"
                      }
              ${
                selectedLesson?.id === section.quizId && selectedLesson?.isQuiz
                  ? "border-l-4 border-blue-600 text-blue-600 bg-slate-200 dark:bg-gray-700 "
                  : "hover:text-blue-600 "
              }`}
                    onClick={() => handleQuizClick(section.quizId)}
                  >
                    Kviz
                  </ul>
                )}

                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
              </li>
            ))
          ) : (
            <p></p>
          )}
  
          <li
            className={`flex items-center justify-between p-2 cursor-pointer dark:hover:bg-gray-700 hover:bg-slate-200 hover:text-blue-600 
        ${isFinalExamActive?"border-l-4 border-blue-600 text-blue-600 bg-slate-200 dark:bg-gray-700": "hover:text-blue-600 "}`}
            onClick={() => handleButtonClick()}
          >
            Završni test
          </li>

          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="p-4 bg-white rounded shadow-lg dark:bg-primary-800">
                <h2 className="mb-4 text-lg font-bold">
                  Da li ste sigurni da želite da pokrenete završni test?
                </h2>
                <div className="flex justify-end space-x-2">
                  <button
                    className="px-4 py-2 text-white bg-green-500 rounded"
                    onClick={() => confirmNavigation()}
                  >
                    Da
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 rounded"
                    onClick={() => cancelNavigation()}
                  >
                    Ne
                  </button>
                </div>
              </div>
            </div>
          )}
        </ul>
      </div>
      {isFinalExamActive? (
        <QuizPage 
        index={questionIndex}
        onNextQuestion={incrementQuestionIndex}
        answers={selectedAnswers}
        setAnswers={setSelectedAnswers}
        sectionList={sectionList}
      
        
        />
      ) : isQuizActive ? (
        <BasicQuizPage
          selectedSectionId={openSection}
          onNextClick={handleNextClick}
          toggleQuiz={toggleQuizActive}
        />
      ) : (
        <>
          <CourseDetailsContent
            lesson={selectedLesson}
            isLessonCompleted={completedLessons[currentLessonIndex]}
            onComplete={() => handleCompleteLesson(currentLessonIndex)}
            handleProgress={progressionUpdate}
            onNextClick={handleNextClick}
            currentLessonIndex={currentLessonIndex + 1}
            totalLessons={totalLessonsInSection}
            addCheckToLesson={()=>checkAnswersUpdate(selectedLesson?.id)}
            finalExam={isFinalExamActive}
            sections={sections?.sections}
            showModal={setShowModal}
            selectedSection={openSection}
            courseName={sections?.name}
            
          />
        </>
      )}
      <>
      {showMessage &&(
        <Message message={messageText} onClose={closeMessage}/>
      )}
      </>
    </>
  );
}

export default SidebarCourseDetails;
