import React from "react";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "./history";
import ErrorCodePage from "./pages/ErrorCodePage";
import { LoadingProvider } from "./context/LoadingContext";
import PrivateRoute from "./components/PrivateRoute";
import { PageTitleProvider } from "./context/PageTitleContext";
import { ModalProvider } from "./context/ModalContext";
import ResetPasswordEmail from "./pages/Login/ResetPasswordEmail";
import ResetPassword from "./pages/Login/ResetPassword";
import Register from "./pages/Login/Register";
import { useSelector } from "react-redux";
import ResetPassword2 from "./pages/Login/ResetPassword2";
const App = () => {
  const userRole = useSelector((state) => state.auth.user?.role.roleName);
  return (
    <BrowserRouter history={history}>
      <ModalProvider>
        <LoadingProvider>
          <PageTitleProvider>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/dashboard/my-dashboard"
                  element={<Dashboard model="my-dashboard" />}
                />
                <Route
                  path="/dashboard/courses"
                  element={<Dashboard model="courses" />}
                />
                <Route
                  path="/dashboard/tests"
                  element={<Dashboard model="tests" />}
                />
                <Route
                  path="/dashboard/quiz/:id"
                  element={<Dashboard model="quiz" />}
                />
                <Route
                  path="/dashboard/quiz-results"
                  element={<Dashboard model="quiz-results" />}
                />
                <Route
                  path="/dashboard/user-final-exam/:id"
                  element={<Dashboard model="user-final-exam" />}
                />
                <Route
                  path="/course-details/:id"
                  element={<Dashboard model="course-details" />}
                />
                <Route
                  path="/test-details/:id"
                  element={<Dashboard model="test-details" />}
                />
                <Route
                  path="/dashboard/settings"
                  element={<Dashboard model="settingspanel" />}
                />
                <Route
                  path="/dashboard/security-settings"
                  element={<Dashboard model="security-settings" />}
                />
                <Route
                  path="/dashboard/user-courses"
                  element={<Dashboard model="user-courses" />}
                />
                <Route
                  path="/dashboard/users-courses"
                  element={<Dashboard model="users-courses" />}
                />
                <Route
                  path="/dashboard/all-courses"
                  element={<Dashboard model="all-courses" />}
                />
                <Route
                  path="/dashboard/employees"
                  element={<Dashboard model="employees" />}
                />
                <Route
                  path="/dashboard/sendMessage"
                  element={<Dashboard model="sendMessage" />}
                />
                {userRole === "student" ? (
                  <Route path="/" element={<Dashboard model="tests" />} />
                ) : (
                  <Route
                    path="/"
                    element={<Dashboard model="my-dashboard" />}
                  />
                )}
              </Route>
              {/* <Route path="/home" element={<Home />} /> */}
              <Route path="/auth/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/reset-password-1"
                element={<ResetPasswordEmail />}
              />
              <Route
                path="/api/v1/userdetails/learn/verify/:email/:code"
                element={<ResetPassword />}
              />
              <Route
                path="/api/v1/userdetails/reset-password/:email/:code"
                element={<ResetPassword2 />}
              />
              <Route path="/forbidden" element={<ErrorCodePage code="403" />} />
              {/* This route will match any route that hasn't been matched by the above routes */}
              <Route path="*" element={<ErrorCodePage code="404" />} />
            </Routes>
          </PageTitleProvider>
        </LoadingProvider>
      </ModalProvider>
    </BrowserRouter>
  );
};

export default App;
