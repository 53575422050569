import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardWithImage from "./CardWithImage";
import { PageTitleContext } from "../../context/PageTitleContext";


const ListOfElements = ({ elements, typeName }) => {
  //Navigate initialization
  const navigate = useNavigate();
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle(typeName);

  const [categoryName,setCategoryName]=useState([]);
  const [selectedCategory, setSelectedCategory] = useState("HR");

  //filter courses by category
  useEffect(()=>{
    let allCategories = new Set();
    setSelectedCategory((elements && elements[0] && elements[0].categories && elements[0].categories[0]?.name) || "");
    elements?.forEach(element => {
      element?.categories?.forEach(category=>{
        allCategories.add(category.name);
      })
    });
    setCategoryName(Array.from(allCategories));
  
  },[elements])

  const filteredCourses = elements?.filter((course) =>
    course.categories?.some((category) => category.name === selectedCategory)
  );


  const renderElements = () =>
    filteredCourses?.map((element) => {
      return (
        <CardWithImage
          id={element.id}
          title={element.name}
          shortDescription={element.courseDescription}
          progressBarPercentage={element.progression}
          status={element.status}
          // img_path={element.imgName}
          onClick={() => navigate(`/course-details/${element.id}`)}
        />
      );
    });

  return (

    <>
    {/* Category Selector */}
    <div className="flex space-x-4 mb-6 ml-3">
        {categoryName?.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`py-2 px-4 rounded-lg ${
              selectedCategory === category
                ? "bg-primary-800 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {category}
          </button>
        ))}
      </div>

    <div className="grid grid-cols-1 gap-4 px-4 py-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
      {renderElements(filteredCourses)}

    </div>


    </>
  );
};

export default ListOfElements;
