import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useModal } from "../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses, getCourseList } from "../../api/slices/courseSlice";
import { getAllTests, getFinalExamList } from "../../api/slices/finalExamSlice";

const EmployeesSideBar = ({ handleClose, data }) => {
  const [isClosing, setIsClosing] = useState(false);
  const { openModal } = useModal();

  //animation for closing contentSideBar
  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(handleClose, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [isClosing, handleClose]);

  const handleCloseWithAnimation = () => {
    setIsClosing(true);
  };

  const dispatch = useDispatch();
  const { allCourseData } = useSelector((state) => state.courses);
  const { courseList } = useSelector((state) => state.courses);
  const{finalExamList, testsData}= useSelector((state)=>state.finalExams)
  
  useEffect(() => {
    //dispatch(getAllCourses({ id: data.id }));
   // dispatch(getCourseList());
    dispatch(getFinalExamList())
    dispatch(getAllTests({id:data.id}))
  }, []);


  const options = [];
  if (finalExamList) {
    options.push(
      ...finalExamList.map((exam) => ({
        value: exam.finalExamId,
        label: exam.name,
      }))
    );
  }

  // const getCourseRefresh = ()=>{
  //   dispatch(getAllCourses({ id: data.id }));
  // }


  const getTestsRefresh = ()=>{
    dispatch(getAllTests({ id: data.id }));
  }
  return (
    <>
      <div
        className={`absolute block z-40 top-0 h-full right-0 xl:w-1/2 bg-gray-50 border dark:bg-primary-800 animate-slide-in-right md:w-full w-full overflow-y-auto  ${
          isClosing && "animate-close-content-side-bar"
        }`}
      >
        <div className="absolute z-30 block w-6 h-6 rounded-full right-2 lg:visible top-2">
          <XMarkIcon onClick={handleCloseWithAnimation}></XMarkIcon>
        </div>
        <div className="p-10 mt-12">
          <div className="px-12 py-6 rounded-md shadow-md">
            <p className="mt-2 font-semibold ">Ime </p>
            <p className="text-sm">{data.firstName}</p>
            <p className="mt-2 font-semibold ">Prezime</p>
            <p className="text-sm">{data.lastName}</p>
            <p className="mt-2 font-semibold ">Email</p>
            <p className="text-sm">{data.email}</p>
            <p className="mt-2 font-semibold ">ID kompanije</p>
            <p className="text-sm">{data.companyId}</p>
            <p className="mt-2 font-semibold ">Rola</p>
            <p className="text-sm">{data.role.roleName}</p>
            <p className="mt-2 font-semibold ">
              Testovi koji su dodeljeni korisniku
            </p>
            <ul>
              {testsData?.length > 0 ? (
                testsData?.map((course) => (
                  <li key={course.id}>{course.name}</li>
                ))
              ) : (
                <li>Odabrani korisnik nema dodeljenih testova!</li>
              )}
            </ul>
          </div>
          <div className="py-3">
            <button
              onClick={() =>
                openModal("ModalAdd", {
                  content: [
                    { name: "finalExamId", label: "Kurs", type: "dropDown" },
                  ],
                  type: "addUserToTest",
                  options: options,
                  id: data.id,
                  getTestsRefresh:getTestsRefresh
                })
              }
              className="bg-primary-800 dark:bg-primary-800 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
            >
              Dodaj test korisniku
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeesSideBar;
