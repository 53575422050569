import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";


export const ButtonWithArrow = ({ text, onClick }) => {
  return (
    <button className="mr-2 button-info" onClick={onClick}>
      {text}
      <svg
        className="w-3.5 h-3.5 ml-2"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
    </button>
  );
};

export const AddItemButton = ({ fileInputRef }) => {
  const handleButtonClick = () => {
    // Ensure that fileInputRef is not undefined before attempting to click
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <button
      className="p-1.5 bg-green-600 rounded-full dark:bg-green-700 w-9"
      onClick={handleButtonClick}
    >
      <PlusIcon className="text-white" />
    </button>
  );
};

export const CloseModalButton = ({ closeModal }) => {
  return (
    <button
      type="button"
      className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      data-modal-hide="popup-modal"
      onClick={closeModal}
    >
      <svg
        className="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span className="sr-only">Close modal</span>
    </button>
  );
};

export const SubmitButton = ({ type }) => {
  let buttonText;

  switch (type) {
    case "edit":
      buttonText = "Izmeni";
      break;

    default:
      buttonText = "Dodaj";
      break;
  }

  return (
    <button type="submit" className="w-full py-5 button-success">
      {buttonText}
    </button>
  );
};
