import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses } from "../../api/slices/courseSlice";
import TableRowSelection from "../common/tables/TableRowSelection";

// Sample data
const userCourses = Array.from({ length: 30 }, (_, i) => ({
  id: i + 1,
  status: i % 2 === 0 ? "Pending" : "Completed",
  date: `2023-12-${(i % 30) + 1}`,
  amount: (i + 1) * 10,
}));

const headers = [
  { name: "ID", key: "id", sortable: false, filter: false },
  { name: "Status", key: "status", sortable: true, filter: true },
  { name: "Date", key: "date", sortable: true, filter: false },
  { name: "Amount", key: "amount", sortable: false, filter: false },
];

//number of columns in pagination
const itemsPerPage = 5;
//search hint "pretraga po"
const searchText = "ID";

const UsersCourses = () => {
  //const { courseData } = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  //fetch all courses
  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <>
      <TableRowSelection
        data={userCourses}
        headers={headers}
        itemsPerPage={itemsPerPage}
        searchText={searchText}
        button=""
        componentType=""
      />
    </>
  );
};

export default UsersCourses;
