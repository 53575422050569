import {XMarkIcon} from "@heroicons/react/24/outline";
import React, {useState} from "react";


const Message =({message, onClose})=>{
    const [isVisible, setIsVisible]= useState(true);

    return(
        isVisible &&(
            <div className="fixed bottom-0 z-50 flex flex-col items-center p-3 mb-10 mr-10 right-0 transform  bg-red-200 rounded-md shadow-lg animate-message w-96">
                <div className="absolute cursor-pointer top-3 right-3"
                onClick={onClose}>
                    <XMarkIcon className ="w-4 h-4"/>

                </div>

                <div className=" text-center">
                    <p>{message}</p>

                </div>

            </div>
        )
    )
}

export default Message;