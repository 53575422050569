import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuiz, postAnswerToTest } from "../../api/slices/courseSlice";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Loader from "../common/Loader";

const BasicQuizPage = ({ selectedSectionId, onNextClick, toggleQuiz }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [essayAnswer, setEssayAnswer] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [answersResult, setAnswersResult] = useState(null);
  const { quizData,loadingBasicQuiz } = useSelector((state) => state.courses);
  const quiz = quizData?.data?.Quiz || {};
  const currentQuestion = quiz?.questions?.[currentQuestionIndex] || null;
  const [nextQuestionButton, setNextQuestionButton] = useState(false);

  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchQuizData = async () => {
      await dispatch(getQuiz({ id: selectedSectionId }));
    };

    if (selectedSectionId) {
      fetchQuizData();
    }
  }, [dispatch, selectedSectionId]);

  if(!quizData||loadingBasicQuiz){
    return <Loader/>
  }

  const handleMultipleChoiceChange = (optionId) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
    } else {
      setSelectedOptions([...selectedOptions, optionId]);
    }
  };

  const handleSingleChoiceChange = (optionId) => {
    setSelectedOption(optionId);
  };

  const handleClick = () => {
    onNextClick();
    toggleQuiz();
  };

  const handleSubmit = async () => {
    const response = await dispatch(
      postAnswerToTest({ id: currentQuestion.id })
    );
    if (response.payload && response.payload.data) {
      setAnswersResult(response.payload.data.question.answers);
    }

    if (currentQuestionIndex === quiz.questions.length - 1) {
      setQuizCompleted(true);
    } else {
      setNextQuestionButton(true);
    }
  };

  const nextQuestion = async () => {
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOptions([]);
      setSelectedOption(null);
      setEssayAnswer("");
      setNextQuestionButton(false);
    }
  };

  const isAnswerCorrect = (answerId) => {
    if (!answersResult) return null;
    const answer = answersResult.find((result) => result.answer === answerId);
    return answer ? answer.correct : null;
  };

  //check answer and show true-false icon
  const renderAnswerIcon = (answer) => {
    const isCorrect = isAnswerCorrect(answer.answer);
    if (isCorrect === null) return null;
    return isCorrect ? (
      <CheckIcon className="w-5 h-5 ml-2 text-green-500" />
    ) : (
      <XMarkIcon className="w-5 h-5 ml-2 text-red-500 " />
    );
  };

  const renderAnswerClass = (answer) => {
    const isCorrect = isAnswerCorrect(answer.answer);
    if (isCorrect === null) return null;
    return !isCorrect
      ? "w-full h-5 ml-2 p-4 bg-red-200 rounded-md dark:bg-red-300"
      : "w-full h-5 ml-2  p-4 bg-green-100 rounded-md dark:bg-green-300";
  };

  return (
    <div className="w-full p-6 mb-2 rounded-lg lg:w-3/4 bg-primary-55 dark:bg-primary-800">
      {quiz?.questions && quiz.questions.length > 0 ? (
        <>
          <div className="flex items-center justify-center mt-6 mb-6 space-x-2">
            {[...Array(quiz.questions.length)].map((_, idx) => (
              <div
                key={idx}
                className={`h-2 w-2 rounded-full ${
                  idx < currentQuestionIndex ? "bg-blue-500" : "bg-gray-300"
                }`}
              />
            ))}
          </div>

          {currentQuestion ? (
            <div className="p-6 ml-10 mr-10 rounded-lg bg-primary-55 dark:bg-primary-800 ">
              <h2 className="mb-4 text-xl font-bold">
                {currentQuestion.question}
              </h2>

              {currentQuestion.questionType === "multiple_choice" && (
                <form className="space-y-4">
                  {currentQuestion.answers.map((answer, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between"
                    >
                      <div
                        className={`flex items-center ${() =>
                          renderAnswerClass(answer)}`}
                      >
                        <input
                          type="checkbox"
                          id={`option-${index}`}
                          className="mr-2"
                          checked={selectedOptions.includes(answer.id)}
                          onChange={() => handleMultipleChoiceChange(answer.id)}
                        />
                        <label
                          htmlFor={`option-${index}`}
                          className="text-gray-700 dark:text-white"
                        >
                          {answer.answer}
                        </label>
                      </div>
                      {renderAnswerIcon(answer)}
                    </div>
                  ))}
                </form>
              )}

              {currentQuestion.questionType === "single_choice" && (
                <form className="space-y-4">
                  {currentQuestion.answers.map((answer, index) => (
                    <div
                      key={index}
                      className={`flex items-center justify-between ${renderAnswerClass(
                        answer
                      )}`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id={`option-${index}`}
                          name="single-choice"
                          className="mr-2"
                          checked={selectedOption === answer.id}
                          onChange={() => handleSingleChoiceChange(answer.id)}
                        />
                        <label
                          htmlFor={`option-${index}`}
                          className={`text-gray-700 dark:text-white `}
                        >
                          {answer.answer}
                        </label>
                      </div>
                      {renderAnswerIcon(answer)}
                    </div>
                  ))}
                </form>
              )}

              {currentQuestion.questionType === "essay" && (
                <form className="space-y-4">
                  <textarea
                    className="w-full p-2 border rounded-lg"
                    rows="5"
                    placeholder="Napišite odgovor ovde..."
                    value={essayAnswer}
                    onChange={(e) => setEssayAnswer(e.target.value)}
                  />
                </form>
              )}
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => handleSubmit(currentQuestion.id)}
                  className="flex px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg"
                >
                  Odgovori
                </button>

                {nextQuestionButton && !quizCompleted ? (
                  <button
                    type="button"
                    onClick={() => nextQuestion()}
                    className="flex px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg "
                  >
                    Sledeće pitanje
                  </button>
                ) : (
                  ""
                )}

                {quizCompleted ? (
                  <button
                    type="button"
                    onClick={() => handleClick()}
                    className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg"
                  >
                    Završi kviz
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <p>No questions available.</p>
          )}
        </>
      ) : (
        <p>Loading quiz...</p>
      )}
    </div>
  );
};

export default BasicQuizPage;
