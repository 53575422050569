import React from "react";

// progress shows completed lessons number
const CourseProgressBar = ({ currentLesson, totalLessons }) => {
  const percentage = (currentLesson / totalLessons) * 100;

  return (
    <div className="w-full h-1 overflow-hidden bg-gray-200 rounded-lg">
      <div
        className="leading-none text-center text-white bg-blue-500"
        style={{ width: `${percentage}%`, height: "100%" }}
      ></div>
    </div>
  );
};

export default CourseProgressBar;
