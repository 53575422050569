import React from "react";
import { Form, Field } from "react-final-form";
import { InputField } from "../common/FormComponents";
import { useSelector } from "react-redux";

const MyProfile = () => {
  const onSubmit = (values) => {
    //  logiku za rukovanje podacima
   
  };
  const currentUser = useSelector((state) => state.auth.user);

  const initialData = {
    name: `${currentUser?.firstName}`,
    lastName: `${currentUser?.lastName}`,
    email: `${currentUser?.email}`,
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialData}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="w-full ">
          <div>
            <h2 className="p-4 mb-4 text-xl font-bold">Moj profil</h2>
            <hr className="p-2 mb-4 border-gray-400 opacity-30" />
          </div>
          <div className="flex flex-col lg:w-1/2 lg:flex-row lg:gap-4 md:w-full md:flex-col">
            <div className="flex-1 ">
              <label
                htmlFor="name"
                className="py-1 text-lg font-bold md:text-base"
              >
                Ime
              </label>
              <Field
                name="name"
                component={InputField}
                type="text"
                disabled={true}
                className="block w-full text-sm text-black border-gray-300 rounded-md"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="lastName"
                className="py-1 text-lg font-bold md:text-base"
              >
                Prezime
              </label>
              <Field
                name="lastName"
                component={InputField}
                type="text"
                disabled={true}
                className="block w-full text-sm text-black border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="lg:w-1/2 lg:mt-4 md:w-full">
            <label
              htmlFor="email"
              className="py-1 text-lg font-bold md:text-base"
            >
              Email
            </label>
            <Field
              name="email"
              component={InputField}
              type="email"
              disabled={true}
              className="block w-full text-black border-gray-300 rounded-md text-md"
            />
          </div>
        </form>
      )}
    />
  );
};

export default MyProfile;
