import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ContentSideBar from "../dashboard_components/ContentSideBar";
import { useSelector } from "react-redux";



const CardWithImage = ({
  id,
  title,
  shortDescription,
  onClick,
  status,
  img_path,
  progressBarPercentage,
}) => {
  const [showDetails, setShowDetail] = useState(false);

  const handleClick = () => {
    setShowDetail(true);
  };
  const handleClose = () => {
    setShowDetail(null);
  };
  const { courseData } = useSelector((state) => state.courses);

  return (
    <>
      <div className="relative flex flex-col overflow-hidden border border-gray-200 rounded-lg shadow bg-primary-55 dark:bg-primary-800 dark:border-gray-800">
        <div className="cursor-pointer" onClick={onClick}>
          <Link href="#">
            <img
              className="w-full lg:h-60 rounded-t-md md:h-44"
              src={require(`../../assets/images/pozadina.jpg`)}
              alt=""
            />
          </Link>
          <div className="flex flex-col flex-grow p-5 ">
            <Link href="#">
              <div className="flex justify-between w-full mb-2 items-center">
                <h5 className="items-center flex-auto text-xl font-bold tracking-tight text-gray-900 dark:text-white ">
                  {title}
                </h5>
                <span className="w-7 h-7">
                  <ChevronRightIcon />
                </span>
              </div>
            </Link>
            <div className="flex flex-auto mb-4 ">
              <p className="font-normal text-gray-700 dark:text-gray-400">
                {shortDescription}
              </p>
            </div>
            <div>
              <p className="text-xs font-light"> {status}</p>
            </div>
          </div>
        </div>

        {/* <button
          className="p-2 mb-2 ml-2 mr-2 border rounded-lg dark:text-white dark:border-gray-600"
          onClick={handleClick}
        >
          Detalji o kursu
        </button> */}
      </div>
      {/* {showDetails && (
        <ContentSideBar handleClose={handleClose} data={courseData} id={id} />
      )} */}
    </>
  );
};

export default CardWithImage;
