import React from "react";

const ProgressBar = ({ percentage }) => {
  return (
    <div className="w-full h-1 overflow-hidden bg-gray-200 rounded-lg">
      <div
        className="leading-none text-center text-white bg-blue-500"
        style={{ width: `${percentage}%`, height: "100%" }}
      ></div>
    </div>
  );
};

export default ProgressBar;
