import apiService from "./apiService";

//GET all user courses
const fetchCourses = async () => {
  const response = await apiService.getData(`user-courses`);
  const courses = response?.data?.courses;
  return courses;
};

//GET course details
const getCourseDetails = async (id) => {
  const response = await apiService.getData(`courses/${id}`);
  const courseDetails = response;
  return courseDetails;
};

//get lessons for section
const getLessons = async (id) => {
  const response = await apiService.getData(`user-sections/${id}`);
  return response;
};

//set lesson status completed
const setLesonCompleted = async (data) => {
  const { id } = data;
  const response = await apiService.putData(`user-lessons/completed/${id}`);
  return response;
};

//set section status completed
const setSectonCompleted = async (data) => {
  const { id } = data;
  const response = await apiService.putData(`user-sections/completed/${id}`);
  return response;
};

//set course status completed
const setCourseCompleted = async (data) => {
  const { id } = data;
  const response = await apiService.putData(`user-courses/completed/${id}`);
  return response;
};

//get final exam
const getFinalExam = async (id) => {
  const response = await apiService.getData(`user-courses/final-quiz/${id}`);
  return response;
};

//get quiz inside sections
const getQuiz = async (id) => {
  const response = await apiService.getData(`user-sections/quiz/${id}`);
  return response;
};

//post answer to basic quiz
const postAnswerToTest = async (data) => {
  const response = await apiService.postData(
    `user-sections/question/answer`,
    data
  );
  return response;
};

//post answer to basic quiz
const postAnswerToFinalExam = async (data) => {
  const response = await apiService.postData(`user-courses/submit-exam`, data);
  return response;
};

//get certificate for final exam
const getCertificate = async (data) => {
  try {
    // Fetch the certificate data as a blob
    const response = await apiService.getData(
      `user-courses/create-cert/${data}`,
      {
        responseType: "blob", // Ensure the response is a Blob (binary data)
      }
    );

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileName = `certificate.pdf`;

    // Create a link element to download the Blob
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the link element and revoke the Blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error downloading certificate:", error);
  }
};

//GET all courses for user
const getAllCourses= async (id) => {
  const response = await apiService.getData(`user-courses/user-course-info/${id}`);
  const allcourses = response?.data?.courses;
  return allcourses;
};

//GET course list
const getCourseList= async () => {
  const response = await apiService.getData(`courses/list`);
  const allcourses = response?.data?.courses;
  return allcourses;
};

//post course to user
const postCourseToUser= async (data) => {
  const response = await apiService.postData(`user-courses`, data);
  return response;
};


export const courseService = {
  fetchCourses,
  getCourseDetails,
  getLessons,
  getFinalExam,
  getQuiz,
  postAnswerToTest,
  postAnswerToFinalExam,
  getCertificate,
  setLesonCompleted,
  setSectonCompleted,
  setCourseCompleted,
  getAllCourses,
  getCourseList,
  postCourseToUser
};
