import React, { useEffect } from "react";
import ListOfElements from "../common/ListOfElements";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses } from "../../api/slices/courseSlice";
import notfoundImg from "../../assets/images/notfound.png";

const Courses = () => {
  const { courseData } = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  //fetch all courses
  useEffect(() => {
    if (!courseData) {
      dispatch(fetchCourses());
    }
  }, [courseData]);
  
  if (courseData?.length === 0) {
    return (
      <div class="flex items-center justify-center h-screen bg-gray-100">
        <img src={notfoundImg} alt="Description of the image" />
      </div>
    );
  }
  return (
    <>
    
      <ListOfElements elements={courseData} typeName={""} />
    </>
  );
};

export default Courses;
