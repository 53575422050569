import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageTitleContext } from "../../../context/PageTitleContext";
import { useDispatch, useSelector } from "react-redux";
import { getTestResults } from "../../../api/slices/testSlice";
import CardWithImage from "../../common/CardWithImage";
import Loader from "../../common/Loader";


const AllCourses = () => {
    //Navigate initialization
    const navigate = useNavigate();
    const { setPageTitle } = useContext(PageTitleContext);
    // setPageTitle("Rezultati kurseva");
  
    const [categoryName,setCategoryName]=useState([]);
    const [selectedCategory, setSelectedCategory] = useState("HR");
  
    const { testResults, loading } = useSelector((state) => state.testResults);
    const [testResultsMap, setTestResultsMap] = useState(new Map());  
  
    const dispatch = useDispatch();
  
    // Fetch all courses 
    useEffect(() => {
      dispatch(getTestResults());
    }, [dispatch]);
  
    // Ažuriraj mapu kad god se testResults promeni
    useEffect(() => {
      if (testResults) {
        testMap(testResults);
      }
    }, [testResults]);
  
  //Check if data fetching is pedding or if there is no data, in both cases
  if (loading || !testResults) {
    return <Loader />;
  }
  
    const testMap = (testResults) => {
      const results = testResults?.results;
      if (results && Array.isArray(results)) {
        // Nova instanca mape
        const newTestResultsMap = new Map();
  
        results.forEach((result) => {
          const courseId = result.course?.id; 
          if (courseId) {
            if (newTestResultsMap.has(courseId)) {
              newTestResultsMap.get(courseId).push(result);
            } else {
              newTestResultsMap.set(courseId, [result]); 
            }
          }
        });
  
        // Ažuriraj state mape
        setTestResultsMap(newTestResultsMap);
      } else {
        console.log("No valid results available.");
      }
    };
    
  
    //filter courses by category
    // useEffect(()=>{
    //   let allCategories = new Set();
    //   setSelectedCategory((elements && elements[0] && elements[0].categories && elements[0].categories[0]?.name) || "");
    //   elements?.forEach(element => {
    //     element?.categories?.forEach(category=>{
    //       allCategories.add(category.name);
    //     })
    //   });
    //   setCategoryName(Array.from(allCategories));
    
    // },[elements])
  
    // const filteredCourses = elements?.filter((course) =>
    //   course.categories?.some((category) => category.name === selectedCategory)
    // );


    const handleClick = (element) => {
      
      navigate('/dashboard/user-courses', {
        state: {
          testResultsMap: Array.from(element) 
        }
      });
    };
    const renderElements = () => {
      return Array.from(testResultsMap.entries()).map(([key, element]) => {
        return (
          <CardWithImage
            key={element[0].course.id} 
            id={element[0].course.id}
            title={element[0].course.name}
            shortDescription={element[0].course.courseDescription}
            // status={element[0].course.active} 
            onClick={() => handleClick(element)} 
          />
        );
      });
    };
    


        // const logCourseNames = (testResults) => {
        //     const results = testResults?.results;
        //     if (results && Array.isArray(results)) {
        //       results.forEach((result, index) => {
        //         if (result.course && result.course.name) {
        //           console.log(`Course name at index ${index}: ${result.course.name}`);
        //         } else {
        //           console.log(`No course name available at index ${index}`);
        //         }
        //       });
        //     } else {
        //       console.log("No valid results available.");
        //     }
        //   };
          
        //   // Primer poziva funkcije
        //   logCourseNames(testResults);
  
    return (
  
      <>
      {/* Category Selector */}
      {/* <div className="flex space-x-4 mb-6 ml-3">
          {categoryName?.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`py-2 px-4 rounded-lg ${
                selectedCategory === category
                  ? "bg-primary-800 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {category}
            </button>
          ))}
        </div> */}
  
      <div className="grid grid-cols-1 gap-4 px-4 py-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
      {
        renderElements()
      }
   
    
      </div>
  
  
      </>
    );
  };
  
  export default AllCourses;