import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useParams } from "react-router-dom";

const QuizResults = ({ resetQuiz, percentage, check }) => {
  const passingScore = parseFloat(percentage.toFixed(2)); //round to two decimal places
  const isPassed = passingScore >= 70;
  const { id } = useParams();

 

  // download certificate
  const downloadPdf = async (id) => {
    try {
      // Fetch the data
      const token = localStorage
        .getItem("userToken_complit")
        .replace(/^"(.*)"$/, "$1");
      const response = await fetch(
        `https://academy.api.complit.rs/api/v1/user-courses/create-cert/${id}`,
        {
          method: "GET",
          headers: {
            "X-Http-Auth": token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch certificate");
      }

      // Convert response to Blob
      const blob = await response.blob();

      // Create a Blob URL for the PDF
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `certificate.pdf`; // Name of the file
      document.body.appendChild(link);
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      // Revoke the Blob URL after the download
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading certificate:", error);
    }
  };

  // show final exam results
  return (
    <div className="flex items-center justify-center p-2 shadow-lg lg:p-14">
      <div className="w-full p-8 rounded-lg bg-primary-55 dark:bg-primary-800">
        <h2 className="mb-4 text-2xl font-semibold text-center dark:text-white">
          Rezultat kviza
        </h2>
        <div className="flex justify-center mb-4">
          <div
            className={`p-4 rounded-full ${
              isPassed || check==='polozio' ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {isPassed || check==='polozio'? (
              <CheckBadgeIcon className="w-8 h-8 text-green-700" />
            ) : (
              <ExclamationCircleIcon className="w-8 h-8 text-red-700" />
            )}
          </div>
        </div>
        <p className="mb-4 text-lg font-medium text-center text-gray-700 dark:text-gray-300">
          {isPassed || check==='polozio' ? " Čestitamo, položili ste!" : "Rok za polaganje testa je istekao!"}
        </p>
        {check !== 'polozio' &&
        <div className="flex items-center justify-center mb-4">
          <div className="w-1/2 p-4 mr-2 text-center bg-gray-100 rounded-lg shadow dark:bg-primary-750">
          
            <p className="text-3xl font-semibold text-gray-800 dark:text-white">
              {passingScore}%
            </p>
          </div>
        </div>
           }
        <div className="flex justify-center">
          {isPassed || check==='polozio'? (
            <button
              onClick={() => downloadPdf(id)}
              className="px-6 py-2 font-semibold text-white rounded-md bg-primary-750 hover:bg-primary-800"
            >
              Preuzmi sertifikat
            </button>
          ) : (
            // <button
            //   className="px-6 py-2 font-semibold text-white rounded-md bg-primary-750 hover:bg-primary-800"
            //   onClick={resetQuiz}
            // >
            //   Ponovo pokreni test
            // </button>
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizResults;
