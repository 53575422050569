import ErrorCodePage from "../../pages/ErrorCodePage";
import Proba from "../common/Proba";
import CourseDetails from "../course_components/CourseDetails";
import Courses from "../course_components/Courses";
import QuizPage from "../course_components/QuizPage";
import QuizResults from "../course_components/QuizResults";
import MyProfile from "../settings/MyProfile";
import SecuritySettings from "../settings/SecuritySettings";
import SettingsPanel from "../settings/SettingsPanel";
import UserCourses from "../course_components/course-results/UserCourses";
import UsersCourses from "../course_components/UsersCourses";
import ContentSideBar from "./ContentSideBar";
import DashboardNavBar from "./DashboardNavbar";
import MyDashboard from "./my_dashboard/MyDashboard";
import Test from "../tests/Test";
import Employees from "../employees/Employees";
import AllCourses from "../course_components/course-results/AllCourses";
import ResetPassword from "../../pages/Login/ResetPassword";
import SendMessage from "../course_components/SendMessage";

const DashboardPanel = ({ model }) => {
  const renderContent = () => {
    switch (model) {
      case "my-dashboard":
        return <MyDashboard />;
      case "proba":
        return <Proba />;
      case "courses":
        return <Courses />;
      case "tests":
        return <Test />;
      case "user-courses":
        return <UserCourses />;
      case "users-courses":
        return <UsersCourses />;
      case "course-details":
        return <CourseDetails isTest={false} />;
      case "test-details":
        return <CourseDetails isTest={true} />;
      case "all-courses":
        return <AllCourses />;
      case "quiz":
        return <QuizPage />;
      case "course-preview":
        return <ContentSideBar />;
      case "quiz-results":
        return <QuizResults />;
      case "my-profile":
        return <MyProfile />;
      case "security-settings":
        return <SecuritySettings />;
      case "settingspanel":
        return <SettingsPanel />;
      case "employees":
        return <Employees />;
      case "verify-user":
        return <ResetPassword />;
      case "sendMessage":
        return <SendMessage />;
      default:
        return <ErrorCodePage code="404" />;
    }
  };

  return (
    <div
      id="dashboardPanel"
      className="relative flex flex-col w-full h-full min-h-screen overflow-x-hidden bg-primary-54 dark:bg-primary-750 text-primary-800 dark:text-white "
    >
      <DashboardNavBar />
      {renderContent()}
    </div>
  );
};

export default DashboardPanel;
