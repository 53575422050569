import apiService from "./apiService";

//GET data for dashboard
const fetchDashboard = async () => {
  const response = await apiService.getData("company/dashboard");
  const dashboard = response?.data?.dashboard;
  return dashboard;
};

//GET passed tests for dashboard
const getPassedTests = async () => {
  const response = await apiService.getData("company/passed-tests");
  const tests = response?.data?.passedTest;
  return tests;
};

//GET passed tests for dashboard
const getLastPassedTest = async () => {
  const response = await apiService.getData("company/last-passed");
  const lastPassed = response?.data?.results;
  return lastPassed;
};

export const dashboardService = {
  fetchDashboard,
  getPassedTests,
  getLastPassedTest,
};
