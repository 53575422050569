import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
  mustBeString,
  checkPasswordRequirements,
} from "../../utils/helpers/validations";
import { InputField, PasswordInputField } from "../../components/common/FormComponents";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, setPassword } from "../../api/slices/settingsSlice";
import PopupSuccess from "../../components/modals/PopupSuccess";
import logoImg from "../../assets/images/logo3.svg";

const ResetPassword2 = () => {
  const [passwordMatch, setPasswordMatch] = useState(true);

  const {status, message}= useSelector((state)=>state.settings)
  useEffect(() => {
    // provera statusa
    if (status === 'succeeded' || status === 'failed') {
      
    }
  }, [status, message]);
  const {email,code} = useParams();
    const dispatch = useDispatch()

  const onSubmit = (values, form) => {

  const passwordMatch = values.password === values.confirmPassword;
  

    if (passwordMatch) {
      setPasswordMatch(true);
      dispatch(resetPassword({data:{password:values.password, email:email, code:code}}));
      
    } else {
      setPasswordMatch(false);
      // form.reset();
    }
  };

  return (
    <>
      <section className="flex flex-col items-center justify-between min-h-screen bg-gray-50 dark:bg-primary-800">
        <div className="flex items-center justify-center flex-grow w-full">
          <div className="flex flex-col items-center w-full">
          <Link
              to="#"
              className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="h-10 mr-2 w-30" src={logoImg} alt="logo" />
            </Link>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="relative flex flex-col ">
                        {/* Username  input field */}
                        <Field
                          name="password"
                          component={PasswordInputField}
                          type="password"
                          required={true}
                          validate={composeValidators(
                            requiredFieldValidation,
                            checkPasswordRequirements
                          )}
                          placeholder="Unesite Vašu novu lozinku"
                          label="Nova lozinka"
                        />
                        {/* Username  input field */}
                        <span className="mt-2" />
                        <Field
                          name="confirmPassword"
                          component={PasswordInputField}
                          type="password"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Potvrdite Vašu novu lozinku"
                          label="Potvrdite novu lozinku"
                        />
                        {/* {!passwordMatch && (
                          <div className="absolute left-0 text-xs text-red-600 bottom-1">
                            Lozinke se ne poklapaju.
                          </div>
                        )} */}
                      </div>
                      <button
                        type="submit"
                        className="w-full text-white bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        Promeni lozinku
                      </button>
                      
                    </form>
                  )}
                />
                    <div className="flex items-center justify-center mt-0">
                  <Link
                    to="/auth/login"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Uloguj se!
                  </Link>
                </div>
              </div>
          
            </div>
            {status === 'succeeded' && <PopupSuccess label="Uspešno promenjena lozinka, sada možete da se ulogujete na Vaš profil!" type="success"/>}
            {status === 'failed' && <PopupSuccess label="Došlo je do greške, pokušajte ponovo." type="error"/>}
            {!passwordMatch && <PopupSuccess label="Lozinke se ne poklapaju, pokušajte ponovo." type="error"/>}
          </div>
          
        </div>
      </section>
    </>
  );
};

export default ResetPassword2;
