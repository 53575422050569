import axios from "axios";
import { logout, setError, setUser, startLoading } from "../slices/authSlice";

// ------------------------------------- Async thunk for user login---------------------------------
export const login = (credentials) => async (dispatch) => {
  try {
    dispatch(startLoading());

    const response = await axios.post(
      "https://academy.api.complit.rs/api/v1/auth",
      credentials
    );

    const token = response.data.data.token;
    localStorage.setItem("userToken_complit", JSON.stringify(token));
    dispatch(loadUser(token));
  } catch (error) {
    dispatch(setError(error.response?.data?.errors?.error));
  }
};

// -------------------------------------------Function to make API call for loading user data ----------------------
export const loadUser = (token) => async (dispatch) => {
  try {
    const response = await axios.get(
      "https://academy.api.complit.rs/api/v1/userdetails",
      {
        headers: {
          "X-Http-Auth": `${token}`,
        },
      }
    );
    dispatch(setUser(response.data.data));
  } catch (error) {
    dispatch(setError(error.response.data.error));
  }
};

// --------------------------- Async thunk for user logout ---------------------------
export const logoutUser = () => (dispatch) => {
  // Add any cleanup logic if needed

  // Dispatch action to logout user
  dispatch(logout());
};
export const authService = {};
