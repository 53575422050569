import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { courseService } from "../services/courseService";

// get user courses
export const fetchCourses = createAsyncThunk(
  "/courses/get",
  async (data, thunkAPI) => {
    try {
      return await courseService.fetchCourses();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get course details
export const getCourseDetails = createAsyncThunk(
  "/course-details/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await courseService.getCourseDetails(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//get lessons for section
export const getLessons = createAsyncThunk(
  "/lessons/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await courseService.getLessons(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//set lesson status completed
export const setLessonCompleted = createAsyncThunk(
  "/lesson-completed/post",
  async (id, thunkAPI) => {
    try {
      return await courseService.setLesonCompleted(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//set section status completed
export const setSectionCompleted = createAsyncThunk(
  "/section-completed/post",
  async (id, thunkAPI) => {
    try {
      return await courseService.setSectonCompleted(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//set course status completed
export const setCourseCompleted = createAsyncThunk(
  "/course-completed/post",
  async (id, thunkAPI) => {
    try {
      return await courseService.setCourseCompleted(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//get final exam
export const getFinalExam = createAsyncThunk(
  "/final-exam/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await courseService.getFinalExam(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//get quiz
export const getQuiz = createAsyncThunk("/quiz/get", async (data, thunkAPI) => {
  const { id } = data;
  try {
    return await courseService.getQuiz(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message;
    return thunkAPI?.rejectWithValue(message);
  }
});

//post answer to basic quiz
export const postAnswerToTest = createAsyncThunk(
  "/answer/post",
  async (data, thunkAPI) => {
    try {
      return await courseService.postAnswerToTest(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//post answer to final quiz
export const postAnswerFinalExam = createAsyncThunk(
  "/final-exam-answer/post",
  async (data, thunkAPI) => {
    try {
      return await courseService.postAnswerToFinalExam(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//get certificate for final exam
export const getCertificate = createAsyncThunk(
  "/cert/get",
  async (data, thunkAPI) => {
    try {

      return await courseService.getCertificate(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get courses for user
export const getAllCourses = createAsyncThunk(
  "/all-courses/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await courseService.getAllCourses(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get course list
export const getCourseList = createAsyncThunk(
  "/course-list/get",
  async (data, thunkAPI) => {
    try {
      return await courseService.getCourseList();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

//post course to user
export const postCourseToUser = createAsyncThunk(
  "/course/post",
  async (data, thunkAPI) => {
    try {
      return await courseService.postCourseToUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const courseSlice = createSlice({
  name: "courses",
  initialState: {
    courseData: null,
    allCourseData:null,
    courseList:null,
    courseDetails: null,
    lessonsData: null,
    finalExamData: null,
    quizData: null,
    loadingBasicQuiz: false,
    loading:false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courseData = action.payload;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCourseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.courseDetails = action.payload;
      })
      .addCase(getCourseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLessons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLessons.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonsData = action.payload;
      })
      .addCase(getLessons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFinalExam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFinalExam.fulfilled, (state, action) => {
        state.loading = false;
        state.finalExamData = action.payload;
      })
      .addCase(getFinalExam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getQuiz.pending, (state) => {
        state.loadingBasicQuiz = true;
        state.error = null;
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.loadingBasicQuiz = false;
        state.quizData = action.payload;
      })
      .addCase(getQuiz.rejected, (state, action) => {
        state.loadingBasicQuiz = false;
        state.error = action.error.message;
      })
      .addCase(getAllCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.allCourseData = action.payload;
      })
      .addCase(getAllCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCourseList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseList.fulfilled, (state, action) => {
        state.loading = false;
        state.courseList = action.payload;
      })
      .addCase(getCourseList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default courseSlice.reducer;
