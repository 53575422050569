import apiService from "./apiService";

//get all employees
const fetchMembers = async () => {
  const response = await apiService.getData(`userdetails/employees`);
  return response;
};

//create new user
const createUser = async (data) => {
  const response = await apiService.postData(`userdetails`, data);
  return response;
};

//deactivate user
const deactivateUser = async (data) => {
  const response = await apiService.deleteData(
    `userdetails/deactivate-user`,
    data
  );
  return response.data;
};

//send message to user
const sendMessage = async (data) => {
  const response = await apiService.postData(`company/send`, data);
  return response;
};

export const memberService = {
  fetchMembers,
  createUser,
  deactivateUser,
  sendMessage,
};
