import React from "react";

//404 Error Page
const ErrorCodePage = ({ code }) => {
  const determineMessage = () => {
    if (code === "404") {
      return <p> Page Not Found - Error 404</p>;
    } else if (code === "500") {
      return <p> Internal Server Error - Error 500</p>;
    } else if (code === "403") {
      return <p> Forbidden - Error 403</p>;
    }

    return <p>Error</p>;
  };

  return (
    <div className="flex w-full h-full ">
      <div className="flex items-center justify-center w-full px-8 py-10">
        <div className="w-1/2 bg-white shadow max-md:w-full dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="text-center shadow-2xl max-md:p-5 dark:shadow-none md:space-y-6 sm:p-8 dark:text-white text-primary-800">
            {determineMessage()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorCodePage;
