import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
  mustBeString,
  checkPasswordRequirements,
  areDatesConsist,
  checkDropdownSelection,

} from "../../utils/helpers/validations";
import {
  Dropdown,
  DropdownCourse,
  InputField,
  PasswordInputField,


} from "../common/FormComponents";
import { useModal } from "../../context/ModalContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DateConsistencyError } from "../../errors/FormValidationErrorField";
import { useDispatch, useSelector } from "react-redux";

import { SubmitButton } from "../common/Button";
import { updatePass } from "../../api/slices/settingsSlice";
import PopupSuccess from "./PopupSuccess";
import { createUser, getMembers } from "../../api/slices/memberSlice";
import { getAllCourses, postCourseToUser } from "../../api/slices/courseSlice";
import { postTestToUser } from "../../api/slices/finalExamSlice";


const ModalAdd = ({ data, isOpen, index }) => {
  //Setting default values for options and allChoices since they are going to be empty in some cases
  //Options is passed for dropDown element
  //allValues are passed as multiple choice
  const {
    content,
    options = "",
    optionsCourse="",
    allChoices = [],
    type = "",
    id = "",
    tid = "",
    buttonType = "",
    members = "",
    removeAssignees = "",
    getTestsRefresh
    

  } = data;
  const { openModal, closeModal, closeAllModals } = useModal();
  const {allCourseData}= useSelector((state)=>state.courses);
 
  // const loading = useSelector((state) => state[type].loading);
  // const error = useSelector((state) => state[type].error);
  const dispatch = useDispatch();
  //..
  if (!isOpen) return null;

  const transformArray = (array) => {
    return array.map((item) => ({ value: item.value }));
  };

  const add = async (values) => {
    if (type === "updatePassword") {
      dispatch(updatePass({ data: { values: values } }));
    }
    if (type === "createUser") {
      dispatch(createUser(values)).then(()=>dispatch(getMembers()))
   
    }
    if(type==="addUserToCourse"){
      dispatch(postCourseToUser({courseId:Object.values(values)[0], userId:String(id)}));
     
    }
    if(type==="addUserToTest"){
      dispatch(postTestToUser({finalExamId:Object.values(values)[0], userId:String(id)})).then(()=>getTestsRefresh())
    }

  };

  //On form submit, do action defined below
  const onSubmit = async (formValues) => {
   
    //refresh page on submit
    const refreshPage = () => {
      // window.location.reload();
    };

    openModal("Modal1", {
      text: "Potvrda",
      close: closeAllModals,
      confirm: () => {
        add(formValues).catch((error) => {
          console.error("Error adding data:", error);
        });
      },
      type: "success",
    });
  };

  //Map initial values to key value pair and pass them as Initial Values, this would
  //will enable appropriate form reset
  //Reduce passed content to one array so it can be used as initial values
  //convert this array into an object where the keys are the field names, and the values are the initial values:
  const initialValues = content.reduce((acc) => {
    return acc;
  }, {});

  const renderData = () => {
    return (
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="py-10 text-gray-400 px-7 lg:px-14 md:px-14"
            >
              {content.map((item, index) => (
                <>
                  {/* Render field names on Serbian.. due to validate function it had to be passed as startDate and endDate */}
                  <div key={item.name} className="relative">
                    <p>{item.label}</p>
                    {/* NA OSNOVU TIPA POLJA MORACE DA SE GENERISE FIELD, NE MOZE SVE NA INPUT, NPR DATUM, rola, tip...*/}
                    {item.type === "text" ? (
                      <Field
                        name={item.name}
                        component={InputField}
                        type="text"
                        required={true}
                        validate={composeValidators(
                          mustBeString,
                          requiredFieldValidation
                        )}
                        icon={true}
                      />
                    ) : item.type === "password" ? (
                      <Field
                        name={item.name}
                        component={PasswordInputField}
                        type="text"
                        required={true}
                        validate={composeValidators(
                          requiredFieldValidation,
                          checkPasswordRequirements
                        )}
                        icon={true}
                      />
                    ) 
                    : item.type === "dropDown" ? (
                      <Field
                        name={item.name}
                        component={Dropdown}
                        type="text"
                        options={options}
                        required={true}
                        validate={checkDropdownSelection}
                        text="Izaberite"
                      />
                    )
                    : item.type === "dropDownCourse" ? (
                      <Field
                        name={item.name}
                        component={DropdownCourse}
                        type="text"
                        optionsCourse={optionsCourse}
                        required={true}
                        validate={checkDropdownSelection}
                        text="Izaberite"
                      />
                    ): item.type === "email" ? (
                      <Field
                        name={item.name}
                        component={InputField}
                        type="text"
                        required={true}
                        validate={composeValidators(requiredFieldValidation)}
                        icon={true}
                      />
                    
                    ): item.type === "date" ? (
                      <div className="relative">
                        <Field
                          name={item.name}
                          component={InputField}
                          required={true}
                          validate={(value, allValues) =>
                            areDatesConsist(value, allValues)
                          }
                        />
                        <DateConsistencyError name={item.name} />
                      </div>
                    ) : (
                     "aaa"
                    )}
                  </div>
                </>
              ))}
              <div className="">
                <SubmitButton type={buttonType} />
              </div>
            </form>
          )}
        />
      </>
    );
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full overflow-y-scroll bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl max-h-full p-4 animate-control">
        <div className="relative w-full rounded-lg shadow bg-primary-50 dark:bg-primary-800">
          {/* <CloseModalButton closeModal={() => closeModal(index)} /> */}
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => closeModal(index)}
          >
            <span className="w-6 ">
              <XMarkIcon />
            </span>
          </button>
          {renderData()} 

        </div>
        
      </div>
    </div>
  );
};

export default ModalAdd;
