// Table.jsx
import React, { useEffect, useState } from "react";
import { useSortableTable } from "../../../custom_hooks/useSortableTable";
import { TagDropdown } from "../FormComponents";
import { useDispatch } from "react-redux";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SearchBar from "../SearchBar";
import Pagination from "../Pagination";
import TableRecursiveRender from "./TableRecursiveRender";
import ContentSideBar from "../../dashboard_components/ContentSideBar";

const TableRowSelection = ({
  data,
  itemsPerPage,
  headers,
  button,
  searchText,
  useSearch,
  getDetails,
}) => {
  const { tableData, handleSorting } = useSortableTable(data);
  //trenutna strana paginacije
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSideBar, setOpenSideBar] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const [uniqueValues, setUniqueValues] = useState([]); // Unique values for each filterable column
  const [selectedValues, setSelectedValues] = useState([]); // Hold all selected filter values(tags)
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);

  const flattenObject = (obj, prefix = "") =>
    Object.entries(obj).reduce(
      (acc, [key, val]) =>
        typeof val === "object" && val !== null
          ? { ...acc, ...flattenObject(val, `${prefix}${key}.`) }
          : { ...acc, [`${prefix}${key}`]: val },
      {}
    );

  const tableDataFlatten = tableData?.map((item) => flattenObject(item));

  // Extract unique values for each column with `filter: true`
  useEffect(() => {
    const values = [];
    headers
      .filter((column) => column.filter)
      .forEach((column) => {
        const columnValues = Array.from(
          new Set(
            tableDataFlatten?.map((item) =>
              JSON.stringify({
                label:
                  column.key === "passed"
                    ? item[column.key]
                      ? "Položen"
                      : "Nije položen"
                    : item[column.key],
                value: item[column.key],
              })
            )
          )
        ).map((item) => JSON.parse(item));
        values[column.key] = columnValues;
      });
    setUniqueValues(values);
  }, []);

  const handleRowClick = (item) => {
    if (getDetails === true) {
      setOpenSideBar(true);
    }
    setSelectedItem(item);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setCurrentPage(1); // Reset to the first page when the search query changes
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleCheckboxChange = (id) => {
    const idExistsIndex = selectedItems.indexOf(id);

    //toggle checked
    if (idExistsIndex !== -1) {
      const freshItems = selectedItems.filter((x) => x !== id);
      setSelectedItems(freshItems);
    } else {
      setSelectedItems((prevState) => [...prevState, id]);
    }
  };

  // Set checkbox to true/false to all the listed rows
  const handleAllCheckboxChange = (e) => {
    if (e.target.checked) {
      const itemIds = [];
      tableData.forEach((item) => {
        if (!selectedItems.includes(item.id)) {
          itemIds.push(item.id);
        }
      });
      setSelectedItems(itemIds);
    } else {
      setSelectedItems([]);
    }
    setCurrentPage(1); // Reset to first page when filters change
  };

  //Sorting table by column name
  const handleSortingChange = (name) => {
    const sortOrder = name === sortField && order === "asc" ? "desc" : "asc";
    setSortField(name);
    setOrder(sortOrder);
    handleSorting(name, sortOrder);
  };

  // Filter data based on selected columns and their filter values
  const filteredData = tableDataFlatten?.filter((item) => {
    // ---------------------------------- SEARCH LOGIC ---------------------------
    // Search by all fields
    const matchesSearch = searchQuery
      ? Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery)
        )
      : true;
    // ----------------------------------=------------------------------------------

    // Column-specific multi-select filters
    const matchesFilters = Object.keys(selectedValues).every((column) => {
      return (
        selectedValues[column].length === 0 ||
        selectedValues[column].includes(item[column])
      );
    });

    return matchesFilters && matchesSearch;
  });

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  // Clear filters for a specific dropdown
  const clearFilter = (columnKey) => {
    setSelectedValues((prevFilters) => ({
      ...prevFilters,
      [columnKey]: [],
    }));
    setCurrentPage(1);
  };

  const handleClose = () => {
    setOpenSideBar(null);
  };

  return (
    <>
      <div className="relative overflow-x-auto sm:rounded-lg">
        <div className="flex items-center justify-between py-3 mr-1 lg:justify-between ">
          <div>
            {/* Dynamic dropdowns for each filterable column */}
            {Object.keys(uniqueValues).map((columnKey) => (
              <TagDropdown
                className="flex"
                text={headers.find((col) => col.key === columnKey)?.name}
                options={uniqueValues[columnKey]}
                selected={selectedValues}
                onSelectionChange={setSelectedValues}
                columnKey={columnKey}
                onClear={() => clearFilter(columnKey)}
              />
            ))}
          </div>
          {useSearch === true && (
            <SearchBar
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              type="search"
              searchBy={searchText}
              position="left"
            />
          )}
        </div>

        <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-primary-800 dark:text-gray-400">
            <tr>
              {/* <td className="flex justify-center pt-4 pl-4">
                <input
                  type="checkbox"
                  onChange={(e) => handleAllCheckboxChange(e)}
                ></input>
              </td> */}
              {/* Map header names/titles */}
              {headers?.map(({ key, name, sortable }) => (
                <th
                  key={key}
                  className="px-6 py-3 text-center cursor-pointer"
                  onClick={sortable ? () => handleSortingChange(key) : null}
                >
                  <div className="flex justify-center">
                    {name}
                    {sortable && <ChevronUpDownIcon className="w-4 h-4" />}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item) => (
              <tr
                key={item.id}
                className={`border-b cursor-pointer dark:hover:bg-primary-800 bg-gray-50 dark:bg-primary-750 dark:border-primary-800 hover:bg-slate-200`}
                onClick={() => handleRowClick(item)}
              >
                {/*=============checkbox==============  */}
                {/* <td className="flex justify-center pt-4 pl-4">
                  <input
                    type="checkbox"
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent row click event
                      handleCheckboxChange(item.id);
                    }}
                    checked={selectedItems.includes(item.id)}
                    onChange={() => {}} // Empty onChange to prevent warning if needed
                  />
                </td> */}
                {/* Go through whole table and map each hader with appropriate value in Object.  */}
                {headers?.map((header, index) => {
                  let data;

                  switch (header["key"]) {
                    case "roleName":
                      if (item["role"]?.roleName === "admin") {
                        data = "Admin";
                        break;
                      } else {
                        data = "Student";
                        break;
                      }
                    case "passed":
                      if (item["passed"] === true) {
                        data = "Položen";
                        break;
                      } else {
                        data = "Nije položen";
                        break;
                      }

                    default:
                      data = item[header["key"]];
                  }

                  return (
                    // This component go through object and recursevly prints elements in case that object is nested, pass "elem" so TableRecursiveRender
                    // know which key to target in nested array of objects (key-value pairs)
                    <TableRecursiveRender
                      data={data}
                      elem="title"
                      index={index}
                    />
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {filteredData?.length > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {openSideBar && selectedItem && (
        <ContentSideBar handleClose={handleClose} data={selectedItem} />
      )}
    </>
  );
};

export default TableRowSelection;
