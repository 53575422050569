import React, { useContext, useEffect } from "react";
import Item from "./Item";
import Activity from "../../common/charts/Activity";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboard,
  getPassedTests,
  getLastPassedTests,
} from "../../../api/slices/dashboardSlice";
import Loader from "../../common/Loader";
import TableRowSelection from "../../common/tables/TableRowSelection";


const MyDashboard = () => {
  //Initialize modal
  // const { openModal } = useModal();

  const dispatch = useDispatch();
  const {
    dashboardInfo,
    loadingDashboard,
    passedTestsData,
    lastPassedTestData,
  } = useSelector((state) => state.dashboard);


  // const { completedTasksData, loadingDashboard2 } = useSelector(
  //   (state) => state.completedTask
  // );
  // const { membersData, loadingMembers } = useSelector((state) => state.member);

  useEffect(() => {
    {
      if (!passedTestsData) {
        dispatch(getPassedTests());
      }
      //get completed tasks
      dispatch(fetchDashboard());
      if (!lastPassedTestData) {
        dispatch(getLastPassedTests());
      }
     
    }
  }, []);

  
  // const { setPageTitle } = useContext(PageTitleContext);
  // setPageTitle("Dashboard");

  //ADD CHECK FOR ERRORS?? RTK QUIERIES FOR DATA FETCHING

  //Check if data fetching is pedding or if there is no data, in both cases
  if (loadingDashboard || !dashboardInfo) {
    return <Loader />;
  }
  // --------------------------------------- Table data ---------------------------------------

  //Trigger this function in case that deletation of team member is confirmed
  // const confirmDelete = (item) => {
  //   console.log("Confirm" + item);
  // };

  //Hardcoded values for user roles
  const options = [
    { value: 1, label: "Administrator" },
    { value: 2, label: "Koordinator" },
  ];

  // Sample data
  const userCourses = Array.from({ length: 5 }, (_, i) => ({
    id: i + 1,
    status: `ime`,
    date: `prezime`,
    amount: `naziv testa`,
  }));

  const headers = [
    { name: "Ime", key: "userFirstName", sortable: false, filter: false },
    { name: "Prezime", key: "userLastName", sortable: false, filter: false },
    {
      name: "Naziv testa",
      key: "finalExamName",
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div className="flex flex-col w-full h-full p-4 ">
      {/* First row */}
      <div className="flex flex-row w-full">
        <div className="flex w-full max-lg:flex-col">
          <div className="w-1/4 max-lg:w-full">
            <Item
              title="Položeni testovi"
              content={dashboardInfo?.passedTests}
            />
          </div>
          <div className="w-1/4 max-lg:w-full">
            <Item
              title="Aktivni nalozi"
              content={79}
            />
          </div>
          <div className="w-1/4 max-lg:w-full ">
            <Item
              title="Aktivni testovi"
              content={dashboardInfo?.activeTests}
            />
          </div>
          <div className="w-1/4 max-lg:w-full ">
            <Item title="Zaposleni" content={dashboardInfo?.employeesCount} />
          </div>
        </div>
      </div>

      {/* Second row */}

      <div className="flex flex-row w-full h-full p-3 max-lg:p-1 max-lg:flex-col">
        <div className="flex w-3/5 border border-gray-200 rounded-lg shadow bg-primary-55 shoradow max-lg:w-full dark:bg-primary-800 dark:border-gray-700">
          <Activity data={passedTestsData} />
        </div>
        <div className="w-2/5 max-lg:pt-4 lg:pl-8 max-lg:w-full ">
          <div className="w-full h-full border border-gray-200 rounded-lg shadow bg-primary-55 dark:bg-primary-800 dark:border-gray-700">
            <div className="">
              <p className="flex justify-center mt-2"> Poslednjih 5 položenih testova</p>
              {lastPassedTestData &&(
              <TableRowSelection
                data={lastPassedTestData}
                headers={headers}
                itemsPerPage={5}
                useSearch={false}
                getDetails={false}
              />
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
