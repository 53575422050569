import { useParams } from "react-router-dom";
import apiService from "./apiService";

//GET last login
const fetchLastLogin = async () => {
    const response = await apiService.getData("userdetails/last-login");
    const lastLogin = response?.data;
    return lastLogin;
  };

//get all active sessins for setting control
const getActiveSessions = async () => {
    const response = await apiService.getData("userdetails/active-sessions");
    const activeSessions = response?.data;
    return activeSessions;
  };

//reset all active sessions, excluding the one you are using
const resetActiveSessions = async () => {
    const response = await apiService.getData("userdetails/reset-sessions");
    const resetSessions = response?.data;
    return resetSessions;
  };

//update password (set new password)
const updatePass = async (data) => {
    const response = await apiService.putData(
      "userdetails/update/password",
      data
    );
    return response;
  };

  //GET registration code for set new password
const verifyUserRegistrationCode = async () => {
  const response = await apiService.getData("userdetails/learn/verify/:email/:code");
  const code = response?.data;
  return code;
};


//set password for created new acount
const setPassword = async (data) => {
  const response = await apiService.postData(
    `userdetails/learn/verify/${data.data.email}/${data.data.code}`,
    {password:data.data.password}
  );
  return response;
};

  //GET registration code
  const getVerificationCode = async () => {
    const response = await apiService.getData("userdetails/reset-password/:email/:code");
    const code = response?.data;
    return code;
  };

//reset pasword with email
const resetPasswordEmail = async (data) => {
  const response = await apiService.postData(
    `userdetails/reset-password`,
    {email:data.data.email}
  );
  return response;
};

//reset password with email
const resetPassword = async (data) => {
  const response = await apiService.postData(
    `userdetails/reset-password/${data.data.email}/${data.data.code}`,
    {password:data.data.password}
  );
  return response;
};

  export const settingsService = {
    fetchLastLogin,
    getActiveSessions,
    resetActiveSessions,
    updatePass,
    verifyUserRegistrationCode,
    setPassword,
    getVerificationCode,
    resetPasswordEmail,
    resetPassword
 
  };