import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { testService } from "../services/testService";

// get test result
export const getTestResults= createAsyncThunk(
    "/testResults/get",
    async (thunkAPI) => {
      try {
        return await testService.fetchTestResults();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message;
        return thunkAPI?.rejectWithValue(message);
      }
    }
  );

  const testSlice = createSlice({
    name: "testResults",
    initialState: {
      testResults: null,
      loading: false,
      error: null,
      status: null,
    message: ''
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getTestResults.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getTestResults.fulfilled, (state, action) => {
          state.loading = false;
          state.testResults = action.payload;
        })
        .addCase(getTestResults.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
    },
  });
  
  export default testSlice.reducer;