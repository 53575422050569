import apiService from "./apiService";

//get final exam for page 'Moji testovi'
const getFinalExams = async () => {
    const response = await apiService.getData(`final-exam`);
    return response;
  };

//get final exam for page 'Moji testovi'
const generateFinalExam = async (id) => {
    const response = await apiService.getData(`final-exam/generate/${id}`);
    return response;
  };


  //GET all exams
const getFinalExamList= async () => {
  const response = await apiService.getData(`final-exam/list`);
  const allExams = response?.data?.finalExams;
  return allExams;
};
  
//post test to user
const postTestToUser= async (data) => {
  const response = await apiService.postData(`final-exam/add`, data);
  return response;
};

//GET all tests for user
const getAllTests= async (id) => {
  const response = await apiService.getData(`final-exam/user-exams/${id}`);
  const allTetsts = response?.data?.finalExams;
  return allTetsts;
};


  export const finalExamService = {
    getFinalExams,
    generateFinalExam,
    getFinalExamList,
    postTestToUser,
    getAllTests
  };