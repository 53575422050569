import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
} from "../../utils/helpers/validations";
import { InputField } from "../../components/common/FormComponents";
import logoImg from "../../assets/images/logo3.svg";


const Register = () => {


  const onSubmit = () => {
 console.log("submit")
  };

  return (
    <>
      <section className="flex flex-col items-center justify-between min-h-screen bg-gray-50 dark:bg-primary-750">
        <div className="flex items-center justify-center flex-grow w-full">
          <div className="flex flex-col items-center w-full px-5">
            <Link
              to="#"
              className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="h-10 mr-2 w-30" src={logoImg} alt="logo" />
            </Link>
            <div className="mb-2 text-xl dark:text-white"></div>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:px-0 dark:bg-primary-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="relative flex flex-col">
                        {/* Username  input field */}
                        <Field
                          name="firstName"
                          component={InputField}
                          type="text"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Unesite Vaše ime"
                          label="Ime"
                        />
                          <Field
                          name="lastName"
                          component={InputField}
                          type="text"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Unesite Vaše prezime "
                          label="Prezime"
                        />

                        <Field
                          name="email"
                          component={InputField}
                          type="password"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Unesite Vaš email"
                          label="Email"
                        />
                        <Field
                          name="userName"
                          component={InputField}
                          type="password"
                          required={true}
                          validate={composeValidators(requiredFieldValidation)}
                          placeholder="Unesite Vaše korisničko ime"
                          label="Korisničko ime"
                        />
                      </div>
            
                      <button
                        type="submit"
                        className="w-full text-white bg-primary-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                       Registruj nalog
                      </button>
                      <div className="flex items-center justify-center mt-3">
                  <Link
                    to="/auth/login"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                     Uloguj se sa kreiranim nalogom!
                  </Link>
                </div>
                    </form>
                  )}
                />

             
             
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
