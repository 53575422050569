import apiService from "./apiService";

//GET test results
const fetchTestResults = async () => {
    const response = await apiService.getData("user-courses/test-results");
    const testResults = response?.data;
    return testResults;
  };

  export const testService = {
    fetchTestResults,
 
  };