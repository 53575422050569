import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardWithImage from "../common/CardWithImage";
import { useDispatch, useSelector } from "react-redux";
import { getFinalExams} from "../../api/slices/finalExamSlice";
import Loader from "../common/Loader";

const Test = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

const {finalExamsData, loadingFinalExam}= useSelector((state)=>state.finalExams)


const elements = finalExamsData?.data?.finalExams;

useEffect(()=>{
  dispatch(getFinalExams());
},[])

if(loadingFinalExam || !finalExamsData){
  return <Loader/>
}

  const renderElements = (elements) =>
    elements?.map((element) => {
      return (
        <CardWithImage
          title={element.name}
          shortDescription={element.description}
          // img_path={element.imgName}
          onClick={() => navigate(`/test-details/${element.id}`)}
        />
      );
    });

  return (
    <>
    <div className="grid grid-cols-1 gap-4 px-4 py-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
      {renderElements(elements)}
    </div>
    </>
  );
};

export default Test;
