import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../context/ModalContext";
import { getActiveSessions, getLastLogin, resetSessions, resetStatusUpdatePass } from "../../api/slices/settingsSlice";
import { formatDateTime } from "../../utils/helpers/formatDate";
import PopupSuccess from "../modals/PopupSuccess";
const SecuritySettings = () => {

    const onSubmit = (values) => {
        
      };
      const { status, message } = useSelector((state) => state.settings);

 

      const dispatch = useDispatch();
      const currentUser = useSelector((state) => state.auth.user);
      const { openModal,closeModal } = useModal();
      const [showAll, setShowAll] = useState(false);
      const {loginData}= useSelector((state)=>state.settings)
      const { activeSessionsData } = useSelector((state) => state.settings);
      useEffect(()=>{
      
          // dispatch(getLastLogin());
          dispatch(getActiveSessions())
       
      },[dispatch])

      //reset active sessions func
      const handleReset=()=>{
      dispatch(resetSessions()).then(()=>dispatch(getActiveSessions()));
      }

      useEffect(() => {
        //reset status - update passwors
        dispatch(resetStatusUpdatePass()); 
      }, [dispatch]); 

  //showMore button
  const handleShowMore = () => {
    setShowAll(true);
  };
  const displayedSessions = showAll
    ? activeSessionsData?.sessions
    : activeSessionsData?.sessions.slice(0, 2);

    const initialData = {
        email: "email@email.com",
        toggle: false,
        phone: "+123-234-554",
        codes: "9 of 10 left",
        date: "today at 18:45, 123.123.123.123",
        device: "Iphone 11, zemlja pristupa",
        browser: "Chrome, right now",
      };


return(

<Form
      onSubmit={onSubmit}
      initialValues={initialData}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className="w-full h-full">
          <div>
            <h2 className="p-4 mb-4 text-xl font-bold">Security</h2>
            <hr className="p-2 mb-4 border-gray-400 opacity-30" />
          </div>
          <div className="flex items-center justify-between mb-6 md:w-full lg:w-1/2">
            <div className="flex justify-between w-full">
              <label className="py-1 text-lg font-semibold md:text-base">
                Email
              </label>
              <Field name="email">
                {({ input }) => <div>{currentUser?.email}</div>}
              </Field>
            </div>
          </div>
          <div className="flex items-center justify-between md:w-full lg:w-1/2">
            <div>
              <p className="py-1 text-lg font-semibold md:text-base">Lozinka</p>
            </div>
            <div>
              <button
                onClick={() =>
                  openModal("ModalAdd", {
                    content: [
                      {
                        name: "oldPassword",
                        label: "Unesite staru lozinku",
                        type: "password",
                      },
                      {
                        name: "password",
                        label: "Unesite novu lozinku",
                        type: "password",
                      },
                      {
                        name: "confirmPassword",
                        label: "Potvrdite novu lozinku",
                        type: "password",
                      },
                    ],
                    buttonType: "edit",
                    type: "updatePassword",
                  })
                }
                className="text-blue-500 underline focus:outline-none"
              >
                Promeni lozinku?
              </button>
            </div>
          </div>
          {status === 'succeeded' && <PopupSuccess label="Uspešno izmenjena lozinka!" type="success"/>}
          {status === 'failed' && <PopupSuccess label="Došlo je do greške, pokušajte ponovo." type="error"/>}
          <hr className="p-3 mt-4 border-gray-400 md:w-full lg:w-1/2 opacity-30" />
          {/* <div className="w-full mb-8">
            <label className="py-1 text-lg font-semibold md:text-base">
              Poslednje logovanje
            </label>
            <Field name="date">
              {({ input }) => (
                <div>
                  {loginData?.ipAddress} <br />{" "}
                  {formatDateTime(loginData?.lastLogin)}
                 
                </div>
              )}
            </Field>
          </div> */}
          {/* <div className="flex items-center justify-between md:w-full lg:w-1/2">
            <div>
              <label className="py-1 text-lg font-semibold md:text-base">
                Broj aktivnih sesija ({activeSessionsData?.sessions?.length})
              
              </label>
            </div>
            <div>
              <button
                onClick={handleShowMore}
                className="font-bold text-blue-500 focus:outline-none"
              >
                Više
              </button>
            </div>
          </div> */}
          {/* <div className="w-full overflow-auto max-h-64">
            {displayedSessions?.map((session, index) => (
              <div key={index}>
                <Field name={`device-${index}`}>
                  {({ input }) => (
                    <div>
                      {session.device} , {session.ip}
                      <br />
                      <div className="text-sm">
                        {session.browser} , {formatDateTime(session.createdAt)}
                      </div>
                    </div>
                  )}
                </Field>
                <hr className="p-3 mt-4 border-gray-400 opacity-30 md:w-full lg:w-1/2" />
              </div>
            ))}
          </div> */}

          {/* <div className="flex flex-col  items-center justify-center md:w-full lg:w-1/2">
            <div className="flex items-center justify-center">
              <button className="button-info" onClick = {handleReset} >
                Resetuj sve aktivne sesije
              </button>
            </div>
            <div className="flex justify-center text-center mt-2">
              <p className="text-xs w-1/2">
                *Resetovanjem sesije izbacujete ostale korisnike sa drugih
                uređaja osim vašeg uređaja na kome ste trenutno logovani
              </p>
            </div>
          </div> */}
        </form>
      )}
    />
  );

};
export default SecuritySettings;