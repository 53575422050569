// Content.js
import { useDispatch, useSelector } from "react-redux";
import CourseProgressBar from "./CourseProgressBar";
import InfoCircle from "../common/InfoCircle";
import { useEffect, useState } from "react";


function CourseDetailsContent({
  lesson,
  onNextClick,
  currentLessonIndex,
  totalLessons,
  isLessonCompleted,
  onComplete,
  handleProgress,
  addCheckToLesson,
  finalExam,
  sections,
  showModal,
  selectedSection,
  courseName

}) {
  const { lessonsData } = useSelector((state) => state.courses);
  const {finalExamsData}= useSelector((state)=>state.finalExams)
  const [checked, setChecked] = useState(false);


const dispatch = useDispatch();

  useEffect(() => {
    if (lesson) {
      setChecked(false);
    }
  }, [lesson]);

  const changed = () => {
     onComplete();
    if (lesson.status === "In progress" && !checked) {
      handleProgress(lesson.sectionId);
    }
    setChecked(true);
    addCheckToLesson();
   
  };

  // const handleFinishLesson = () => {
  //   // Ažuriraj stanje završenih lekcija
  //   const newCompletedLessons = {
  //     ...completedLessons,
  //     [lesson.sectionId]: [
  //       ...(completedLessons[lesson.sectionId] || []),
  //       lesson.id,
  //     ],
  //   };

  //   setCompletedLessons(newCompletedLessons); // Ažuriraj stanje
  //   onComplete(); // Pozovi callback za ažuriranje progresa
  // };

  // func to show html code
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  //const [isLessonCompleted, setIsLessonCompleted] = useState(false); // Dodaj stanje za završavanje lekcije

  // const handleCompleteLesson = () => {
  //   dispatch(setLessonCompleted({ id: lesson.id }));
  //   setIsLessonCompleted(true); // Postavi stanje na true kada je lekcija završena
  // };

  const activateFinalExam =()=>{
    showModal(true);

    
  }

  
  return (
    <>
      <div className="relative w-full p-6 mb-2 rounded-lg lg:w-3/4 bg-primary-55 dark:bg-primary-800 min-h-screen">
      {lesson&&
      <>
        <div className="flex items-center justify-between mb-3 rounded-lg bg-primary-55 dark:bg-primary-800 dark:text-white">
          <p className="flex-1 text-base font-medium text-center">
            Lekcija {isNaN(currentLessonIndex) ? 0 : currentLessonIndex} od{" "}
            {totalLessons}
          </p>
          <InfoCircle
            type="info"
            text="Označite lekciju kao pročitanu pre prelaska na sledeću!"
          />
          <button
            className="p-2 border rounded-lg"
            onClick={onNextClick}
            disabled={!isLessonCompleted} // Onemogući dugme dok lekcija nije završena
          >
            Sledeća lekcija
          </button>
          </div>
      
             {/* Progress bar - prikazuje trenutnu lekciju samo kada je završena */}
        <CourseProgressBar
          currentLesson={ currentLessonIndex } // Postavi na 0 dok lekcija nije završena
          totalLessons={totalLessons}
        />
      </>
        
      }
     
     <div className="lg:ml-28 lg:mr-28">

  {lesson ? (
    <>
      <h1 className="mt-4 mb-4 text-2xl font-bold">
        {lessonsData?.data?.section?.name}
      </h1>
      <div className="mb-4">
        <h2 className="font-semibold">{lesson?.name}</h2>
        {/* <p className="font-medium">{lesson?.description}</p> */}
      </div>
      <div dangerouslySetInnerHTML={createMarkup(lesson?.rawHtmlContent)} />
    </>
  ) : selectedSection ? (
    <>
      <h1 className="mt-4 mb-4 text-2xl font-bold">
        {lessonsData?.data?.section?.name}
      </h1>
      <p className="font-medium">
        {lessonsData?.data?.section?.description}
      </p>
    </>
  ) : !sections   ? (
    <div>
        <div>
            <h1 className="text-xl"><b>Dobrodošli!</b></h1>
            <br></br>
            <p>Ovaj test vam omogućava da proverite svoje znanje u oblasti informacionе bezbednosti. Sastavljen je od pažljivo odabranih pitanja koja pokrivaju ključne teme vezane za zaštitu podataka i sigurnost na internetu.</p>
            <br></br>
            <p>Da biste uspešno položili test i dobili sertifikat, potrebno je da ostvarite <b>najmanje 70% tačnih odgovora</b>. Test možete ponoviti onoliko puta koliko želite, čime ćete imati priliku da dodatno usavršite svoje znanje i poboljšate rezultate.</p>
            <br></br>
            <p>Ukoliko ste prethodno uspešno položili test, sertifikat možete preuzeti nakon pokretanja testa.</p>
            <br></br>
            <p>Srećno!</p>
            <div className="mt-10">
            <button className="bg-primary-800 dark:bg-primary-750 rounded bottom-4 p-3 text-white" onClick={()=>activateFinalExam()}> Pokreni test</button>
            </div>
          </div>
    
    </div>
  ) : (
    <div>
    <h1 className="text-xl">Dobrodošli na kurs <b>"{courseName}"</b>!</h1>
    <br></br>
    <p>Potrebno je da pratite pažljivo osmišljene sekcije i lekcije koje će vam pružiti potrebno znanje. </p>
    <p>Na kraju svake sekcije nalazi se kviz sa pitanjima vezanim za obrađeni sadržaj, koji će vam pomoći da utvrdite naučeno.</p>
    <br></br>
    <p><i>Po završetku svih sekcija i lekcija, polagaćete završni test kako biste stekli potvrdu svog znanja.</i></p>
    <br></br>
    <p>Srećno! </p>

  </div>
  )}
</div>


        {lesson && lesson?.status !=="Completed" &&(
          <button
            className={` px-4 py-2 lg:ml-28 mt-20 text-white bg-blue-500 rounded bottom-4 left-4`}
            onClick={() => changed()}
          >
            Završi lekciju
          </button>
        )}
      </div>
    </>
  );
}

export default CourseDetailsContent;
