import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardService } from "../services/dashboardService";

//get data for dashboard
export const fetchDashboard = createAsyncThunk(
  "/dashboard/get",
  async (thunkAPI) => {
    try {
      return await dashboardService.fetchDashboard();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get all completed tasks for dashboard graph
export const getPassedTests = createAsyncThunk(
  "/passedTests/get",
  async (thunkAPI) => {
    try {
      return await dashboardService.getPassedTests();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;

      return thunkAPI?.rejectWithValue(message);
    }
  }
);

// get all completed tasks for dashboard graph
export const getLastPassedTests = createAsyncThunk(
  "/last-passedTests/get",
  async (thunkAPI) => {
    try {
      return await dashboardService.getLastPassedTest();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;

      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardData: null,
    passedTestsData: null,
    dashboardInfo: null,
    lastPassedTestData: null,
    loadingDashboard: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.loadingDashboard = true;
        state.error = null;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.dashboardInfo = action.payload;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.loadingDashboard = false;
        state.error = action.error.message;
      })
      .addCase(getPassedTests.pending, (state) => {
        state.loadingDashboard = true;
        state.error = null;
      })
      .addCase(getPassedTests.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.passedTestsData = action.payload;
      })
      .addCase(getPassedTests.rejected, (state, action) => {
        state.loadingDashboard = false;
        state.error = action.error.message;
      })
      .addCase(getLastPassedTests.pending, (state) => {
        state.loadingDashboard = true;
        state.error = null;
      })
      .addCase(getLastPassedTests.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.lastPassedTestData = action.payload;
      })
      .addCase(getLastPassedTests.rejected, (state, action) => {
        state.loadingDashboard = false;
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
