// App.js
import React, { useEffect, useState } from "react";
import SidebarCourseDetails from "./SideBarCourseDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCourses, getFinalExam } from "../../api/slices/courseSlice";

function CourseDetails({isTest}) {
  const { courseData } = useSelector((state) => state.courses);
  const { id } = useParams();
  const dispatch = useDispatch();

  //fetch all courses
 

  //ovde proveravam id zbog prikaza selektovane kartice
  const selectedData = isTest ? [] :courseData?.find((data) => data.id === Number(id));
  const [selectedSection, setSelectedSelection] = useState(selectedData);

  return (
    <div className="flex flex-col h-screen p-3 md:flex-row">
      {/* Sidebar */}
      <SidebarCourseDetails
        selectedSection={selectedSection}
        sections={selectedData}
        setSelectedSection={setSelectedSelection}
      
      />
    </div>
  );
}

export default CourseDetails;
